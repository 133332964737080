import { MainApi } from "./endpoint";
// import { mockData } from '../utils/tools'

export const createSubject = (payload) =>
  MainApi.post("/admin/subjects", payload);
export const getSubjects = (payload) => MainApi.get("/admin/subjects", payload);
export const updateSubject = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;
  return MainApi.put(`/admin/subjects/${payload.id}`, handlePayload);
};
export const deleteSubject = (payload) =>
  MainApi.delete(`/admin/subjects/${payload.id}`);
