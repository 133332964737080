import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import { uploadFile, getContacts, deleteContact } from "../../api/common";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.UPLOAD_FILE, sagaRunning({ api: uploadFile })),
    takeLatest(TYPES.GET_CONTACTS, sagaRunning({ api: getContacts })),
    takeLatest(TYPES.DELETE_CONTACT, sagaRunning({ api: deleteContact })),
  ]);
}
