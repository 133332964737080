import React from "react";
import { Button } from "antd";
import "antd/es/button/style/css";
import classNames from "classnames";

import "./style.scss";

export default ({ children, className, ...props }) => (
  <Button {...props} className={classNames("button-custom-default", className)}>
    {children}
  </Button>
);
