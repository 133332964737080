import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
// import { withNamespaces } from 'react-i18next'
import { object, string } from "yup";
import Button from "../../../components/button";
import InputPassword from "../../../components/input-password";
import Modal from "../../../components/modal";
import Field from "../../../components/field";
import Notification from "../../../components/notification";
import { TYPES, actions } from "../../../store/actions";

import "./style.scss";
import { forwardInnerRef } from "../../../utils/high-order-functions";

const validationSchema = object().shape({
  oldPassword: string().required("Obligatoire"),
  newPassword: string().required("Obligatoire"),
  confirmPassword: string().required("Obligatoire"),
});

class ChangePassword extends React.Component {
  state = {
    visible: false,
  };

  open = () => {
    this.setState({
      visible: true,
    });
  };

  close = () => {
    this.setState({
      visible: false,
    });
  };

  _onSubmit = ({ oldPassword, newPassword, confirmPassword }) => {
    if (newPassword !== confirmPassword) {
      return Notification.error(
        "Le mot de passe et la confirmation du mot de passe ne sont pas les mêmes",
      );
    }
    const { changePass } = this.props;
    changePass({ newPassword, oldPassword }, (action) => {
      if (action === TYPES.CHANGE_PASS_SUCCESS) {
        this.close();
        return Notification.success("Changement de mot de passe réussi");
      }
    });
  };

  _renderForm = ({ handleSubmit, ...form }) => {
    const {
      accountStore: { submitting },
    } = this.props;

    return (
      <Form className="form-change-pass">
        <div className="content">
          <div className="field-change-pass">
            {/* <Field
              className="field"
              form={form}
              name="id"
              component={Input}
              placeholder="ID"
              disabled
              label="ID"
            /> */}
            <Field
              className="field"
              form={form}
              name="oldPassword"
              placeholder="Mot de passe *"
              type="password"
              component={InputPassword}
              label="Mot de passe"
            />
            <Field
              className="field"
              form={form}
              name="newPassword"
              placeholder="Nouveau mot de passe *"
              type="password"
              component={InputPassword}
              label="Nouveau mot de passe"
            />
            <Field
              className="field"
              form={form}
              name="confirmPassword"
              placeholder="Confirmer le mot de passe *"
              type="password"
              component={InputPassword}
              label="Confirmer le mot de passe"
            />
          </div>
          <div className="change-pass">
            <Button type="basic" className="btn-close" onClick={this.close}>
              Fermer
            </Button>
            <Button
              htmlType="submit"
              className="btn-change-pass"
              type="primary"
              loading={!!submitting}
              onClick={handleSubmit}
            >
              Mise à jour
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  render() {
    const { visible } = this.state;
    const initialValues = {};

    return (
      <Modal
        visible={visible}
        onCancel={this.close}
        destroyOnClose
        title="Changer le mot de passe"
        className="modal-change-pass"
      >
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this._onSubmit}
          component={this._renderForm}
        />
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    accountStore: state.account,
  }),
  {
    changePass: actions.changePass,
  },
)(forwardInnerRef(ChangePassword));
