import { all, takeLatest, takeEvery } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  createAppointment,
  getAppointments,
  updateAppointment,
  deleteAppointment,
  getBooking,
  deleteBooking,
} from "../../api/appointments";

export default function* watcher() {
  yield all([
    takeLatest(
      TYPES.CREATE_APPOINTMENT,
      sagaRunning({ api: createAppointment }),
    ),
    takeLatest(
      TYPES.UPDATE_APPOINTMENT,
      sagaRunning({ api: updateAppointment }),
    ),
    takeLatest(
      TYPES.DELETE_APPOINTMENT,
      sagaRunning({ api: deleteAppointment }),
    ),
    takeEvery(TYPES.GET_APPOINTMENTS, sagaRunning({ api: getAppointments })),
    takeLatest(TYPES.GET_BOOKING, sagaRunning({ api: getBooking })),
    takeLatest(TYPES.DELETE_BOOKING, sagaRunning({ api: deleteBooking })),
  ]);
}
