import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  createCategoryBlog,
  updateCategoryBlog,
  getListCategoriesBlog,
  deleteCategoryBlog,
  getListBlogs,
  createBlog,
  updateBlog,
  deleteBlog,
} from "api/blog";

export default function* watcher() {
  yield all([
    takeLatest(
      TYPES.DELETE_CATEGORY_BLOG,
      sagaRunning({ api: deleteCategoryBlog }),
    ),
    takeLatest(
      TYPES.GET_LIST_CATEGORIES_BLOG,
      sagaRunning({ api: getListCategoriesBlog }),
    ),
    takeLatest(
      TYPES.CREATE_CATEGORY_BLOG,
      sagaRunning({ api: createCategoryBlog }),
    ),
    takeLatest(
      TYPES.UPDATE_CATEGORY_BLOG,
      sagaRunning({ api: updateCategoryBlog }),
    ),
    takeLatest(TYPES.GET_LIST_BLOGS, sagaRunning({ api: getListBlogs })),
    takeLatest(TYPES.CREATE_BLOG, sagaRunning({ api: createBlog })),
    takeLatest(TYPES.UPDATE_BLOG, sagaRunning({ api: updateBlog })),
    takeLatest(TYPES.DELETE_BLOG, sagaRunning({ api: deleteBlog })),
  ]);
}
