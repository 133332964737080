import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LocalStorage from "../utils/storage";
import Loading from "../components/loading/index";
import Header from "../components/header";
import { handleAxiosApi } from "../api/interceptors";
import SideBar from "../components/side-bar";

import "antd/es/layout/style/css";

handleAxiosApi();

const Login = lazy(() => import("../pages/account/login"));
const ChangePassword = lazy(() => import("../pages/account/change-password"));
const ForgotPassword = lazy(() => import("../pages/account/forgot-password"));
const ResetPassword = lazy(() => import("../pages/account/reset-password"));
const Home = lazy(() => import("../pages/home"));
const Settings = lazy(() => import("../pages/settings"));
const NotFound = lazy(() => import("../pages/not-found"));
const AvailableContent = lazy(() => import("../pages/available-content"));
const CentersManagement = lazy(() => import("../pages/centers-management"));
const Marketing = lazy(() => import("../pages/marketing"));
// const MarketingListDetails = lazy(() => import('../pages/marketing/List/ListDetails'))
const MembersManagement = lazy(() => import("../pages/members-management"));
const Center = lazy(() => import("../pages/centers-management/centers"));
const Manager = lazy(() => import("../pages/members-management/managers"));
const Contact = lazy(() => import("../pages/contact"));
const Discount = lazy(() => import("../pages/discount"));
const Internship = lazy(() => import("../pages/internship"));
const Slide = lazy(() => import("../pages/slide-page"));
const CustomerInfo = lazy(() => import("../pages/customer-info"));
const BlogManagement = lazy(() => import("../pages/blog-management"));

const PrivateRoute = ({ condition, redirect, ...props }) => {
  condition = condition();

  if (condition) {
    return <Route {...props} />;
  }
  return <Redirect to={redirect} />;
};

const Routes = () => {
  const _renderLazyComponent = (LazyComponent, params) => (props) => (
    <LazyComponent {...props} {...params} />
  );

  const _renderAuthRoutes = () => (
    <>
      <Suspense
        fallback={
          <div className="content-loading">
            <Loading />
          </div>
        }
      >
        <div className="box-router">
          <div className="side-bar-box">
            <SideBar />
          </div>
          <div className="body-box">
            <Header />
            <Switch>
              <Route exact path="/" component={_renderLazyComponent(Home)} />
              <Route
                exact
                path="/internship"
                component={_renderLazyComponent(Internship)}
              />
              <Route
                exact
                path="/manager"
                component={_renderLazyComponent(Manager)}
              />
              <Route
                exact
                path="/center"
                component={_renderLazyComponent(Center)}
              />
              <Route
                exact
                path="/change-password"
                component={_renderLazyComponent(ChangePassword)}
              />
              <Route
                exact
                path="/settings"
                component={_renderLazyComponent(Settings)}
              />
              <Route
                exact
                path="/not-found"
                component={_renderLazyComponent(NotFound)}
              />
              <Route
                exact
                path="/available-content"
                component={_renderLazyComponent(AvailableContent)}
              />
              <Route
                exact
                path="/centers-management"
                component={_renderLazyComponent(CentersManagement)}
              />
              <Route
                exact
                path="/marketing"
                component={_renderLazyComponent(Marketing)}
              />
              {/* <Route exact path="/marketing/:id" component={_renderLazyComponent(MarketingListDetails)} /> */}
              <Route
                exact
                path="/account-management"
                component={_renderLazyComponent(MembersManagement)}
              />
              <Route
                exact
                path="/slide"
                component={_renderLazyComponent(Slide)}
              />
              <Route
                exact
                path="/contact"
                component={_renderLazyComponent(Contact)}
              />
              <Route
                exact
                path="/discount"
                component={_renderLazyComponent(Discount)}
              />
              <Route
                exact
                path="/customer/:id/detail"
                component={_renderLazyComponent(CustomerInfo)}
              />
              <Route
                exact
                path="/blog-management"
                component={_renderLazyComponent(BlogManagement)}
              />
              <Redirect to="/not-found" />
            </Switch>
          </div>
        </div>
      </Suspense>
    </>
  );

  return (
    <div>
      <Suspense
        fallback={
          <div className="content-loading">
            <Loading />
          </div>
        }
      >
        <Switch>
          <Route exact path="/login" component={_renderLazyComponent(Login)} />
          <Route
            exact
            path="/forgot-password"
            component={_renderLazyComponent(ForgotPassword)}
          />
          <Route
            exact
            path="/reset-password/:email/:token"
            component={_renderLazyComponent(ResetPassword)}
          />
          <Route
            exact
            path="/not-found"
            component={_renderLazyComponent(NotFound)}
          />
          <PrivateRoute
            condition={() => LocalStorage.has("ACCESS_TOKEN")}
            redirect="/login"
            path="/"
            component={_renderAuthRoutes}
          />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Routes;
