import { TYPES } from "../actions";

const INIT_STATE = {
  openSideBar: false,
  tab: "0",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // case TYPES.TOGGLE_SIDE_BAR_REQUEST:
    //   return {
    //     ...state,
    //     submitting: action.type
    //   }
    case TYPES.TOGGLE_SIDE_BAR_SUCCESS:
      return {
        ...state,
        submitting: null,
        openSideBar: !state.openSideBar,
      };
    // case TYPES.TOGGLE_SIDE_BAR_FAILURE:
    //   return {
    //     ...state,
    //     submitting: null,
    //     error: action.error
    //   }
    case TYPES.SET_TAB_SUCCESS:
      return {
        ...state,
        submitting: null,
        tab: action.data?.data,
      };
    default:
      return state;
  }
};
