import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,
  discounts: [],
  count: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_DISCOUNTS_REQUEST:
    case TYPES.CREATE_DISCOUNTS_REQUEST:
    case TYPES.UPDATE_DISCOUNTS_REQUEST:
    case TYPES.DELETE_DISCOUNTS_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.CREATE_DISCOUNTS_SUCCESS:
      return {
        ...state,
        submitting: null,
        discounts: [action.data, ...state.discounts],
      };
    case TYPES.UPDATE_DISCOUNTS_SUCCESS:
      return {
        ...state,
        submitting: null,

        discounts: state.discounts.map((gr) =>
          gr.id === action.data.id ? action.data : gr,
        ),
      };
    case TYPES.DELETE_DISCOUNTS_SUCCESS:
      return {
        ...state,
        submitting: null,

        discounts: state.discounts.filter((r) => r.id !== action.payload.id),
      };
    case TYPES.GET_DISCOUNTS_SUCCESS:
      return {
        ...state,
        submitting: null,
        discounts: action.data.rows,
        count: action.data.count,
      };
    case TYPES.CREATE_DISCOUNTS_FAILURE:
    case TYPES.UPDATE_DISCOUNTS_FAILURE:
    case TYPES.DELETE_DISCOUNTS_FAILURE:
    case TYPES.GET_DISCOUNTS_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
