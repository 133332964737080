import { MainApi } from "./endpoint";
// import { mockData } from '../utils/tools'

export const createAppointment = (payload) =>
  MainApi.post("/admin/appointments", payload);
export const updateAppointment = (payload) =>
  MainApi.put("/admin/appointments", payload);
export const deleteAppointment = (payload) =>
  MainApi.delete(`/admin/appointments/${payload.id}`);
export const getAppointments = (payload) => {
  const handleQuery = { ...payload };
  delete handleQuery.id;

  return MainApi.get(`/admin/appointments/${payload.id}`, handleQuery);
};
export const getBooking = (payload) =>
  MainApi.get(`/admin/appointments/booking/${payload.id}`, payload);
export const deleteBooking = (payload) =>
  MainApi.delete(`/admin/appointments/booking/${payload.id}`, payload);
