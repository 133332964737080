import { MainApi } from "./endpoint";

export const getAllSchedule = (payload) =>
  MainApi.get(`/admin/groups/centers/${payload.id}/schedules`, {
    from: payload.from,
    to: payload.to,
  });
export const getGroupInfo = (payload) =>
  MainApi.get(`/admin/groups/${payload}`);
// export const getCommentGroupId = (payload) => MainApi.get(`/admin/comments/group/${payload}`)
export const getCommentGroupId = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;

  return MainApi.get(`/admin/comments/group/${payload.id}`, handlePayload);
};
export const getAgendaGroupId = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;

  return MainApi.get(`/admin/agendas/group/${payload.id}`, handlePayload);
};
