import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  getTeachers,
  getStudents,
  getParents,
  // sendMailInterview,
  updateTeacher,
  changeStatusTeacher,
  inviteInterview,
  deleteTeacher,
  getManagers,
  updateManager,
  deleteManager,
  createManager,
  getHistoryTransaction,
  getTransactionTeacher,
  updateParent,
  updateStudent,
  updateStatusCustomer,
  deleteCustomer,
  getStudentsByGroup,
  createTeacher,
  updateTransactionTeacher,
} from "../../api/users";

export default function* watcher() {
  yield all([
    takeLatest(
      TYPES.UPDATE_TRANSACTION_TEACHER,
      sagaRunning({ api: updateTransactionTeacher }),
    ),
    takeLatest(TYPES.DELETE_CUSTOMER, sagaRunning({ api: deleteCustomer })),
    takeLatest(
      TYPES.UPDATE_STATUS_CUSTOMER,
      sagaRunning({ api: updateStatusCustomer }),
    ),
    takeLatest(TYPES.UPDATE_STUDENT, sagaRunning({ api: updateStudent })),
    takeLatest(TYPES.UPDATE_PARENT, sagaRunning({ api: updateParent })),
    takeLatest(TYPES.GET_TEACHERS, sagaRunning({ api: getTeachers })),
    takeLatest(TYPES.UPDATE_TEACHER, sagaRunning({ api: updateTeacher })),
    takeLatest(TYPES.CREATE_TEACHER, sagaRunning({ api: createTeacher })),
    takeLatest(TYPES.DELETE_TEACHER, sagaRunning({ api: deleteTeacher })),
    takeLatest(
      TYPES.CHANGE_STATUS_TEACHER,
      sagaRunning({ api: changeStatusTeacher }),
    ),
    takeLatest(TYPES.INVITE_INTERVIEW, sagaRunning({ api: inviteInterview })),
    takeLatest(TYPES.GET_STUDENTS, sagaRunning({ api: getStudents })),
    takeLatest(TYPES.GET_PARENTS, sagaRunning({ api: getParents })),
    takeLatest(TYPES.GET_MANAGERS, sagaRunning({ api: getManagers })),
    takeLatest(TYPES.UPDATE_MANAGER, sagaRunning({ api: updateManager })),
    takeLatest(TYPES.DELETE_MANAGER, sagaRunning({ api: deleteManager })),
    takeLatest(TYPES.CREATE_MANAGER, sagaRunning({ api: createManager })),
    takeLatest(
      TYPES.GET_HISTORY_TRANSACTION,
      sagaRunning({ api: getHistoryTransaction }),
    ),
    takeLatest(
      TYPES.GET_TRANSACTION_TEACHER,
      sagaRunning({ api: getTransactionTeacher }),
    ),
    takeLatest(
      TYPES.GET_STUDENTS_BY_GROUP,
      sagaRunning({ api: getStudentsByGroup }),
    ),
  ]);
}
