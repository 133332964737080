export default [
  "LOGIN",
  "GET_INFO_FIRST_LOAD",
  "TOGGLE_SIDE_BAR",
  "LOGOUT",
  "CHANGE_PASS",
  "UPDATE_PROFILE",
  "HANDLE_SEND_MAIL",
  "RESET_PASSWORD",
  "SET_TAB",

  // CENTER
  "GET_CENTERS",
  "UPDATE_CENTER",
  "CREATE_CENTER",
  "DELETE_CENTER",
  "SELECT_CENTER",

  "CREATE_HOLIDAY",
  "CANCEL_HOLIDAY",

  // ROOM
  "CREATE_ROOM",
  "DELETE_ROOM",
  "UPDATE_ROOM",
  "GET_ROOMS",
  "SET_CURRENT_ROOM",

  // GROUP
  "CREATE_GROUP",
  "UPDATE_GROUP",
  "GET_GROUPS",
  "GET_GROUP_DETAIL",
  "DELETE_GROUP",
  "GET_ALL_GROUPS",
  "GET_ALL_GROUPS_INTERNSHIP",

  "GET_INTERNSHIPS",
  "CREATE_INTERNSHIP",
  "DELETE_INTERNSHIP",
  "UPDATE_INTERNSHIP",
  "SHOW_HOMEPAGE",

  "MOVE_STUDENTS_NEW_GROUP",

  // SUBJECT
  "GET_SUBJECTS",
  "CREATE_SUBJECT",
  "UPDATE_SUBJECT",
  "DELETE_SUBJECT",

  // USER
  "GET_TEACHERS",
  "UPDATE_TEACHER",
  "CREATE_TEACHER",
  "DELETE_TEACHER",
  "CHANGE_STATUS_TEACHER",
  "INVITE_INTERVIEW",
  "GET_STUDENTS",
  "GET_STUDENTS_BY_GROUP",
  "GET_PARENTS",
  "GET_MANAGERS",
  "CREATE_MANAGER",
  "DELETE_MANAGER",
  "UPDATE_MANAGER",
  "GET_HISTORY_TRANSACTION",
  "GET_TRANSACTION_TEACHER",
  "UPDATE_STUDENT",
  "UPDATE_PARENT",
  "UPDATE_STATUS_CUSTOMER",
  "DELETE_CUSTOMER",

  // COMMON
  "UPLOAD_FILE",
  "GET_CONTACTS",
  "DELETE_CONTACT",

  "GET_APPOINTMENTS",
  "CREATE_APPOINTMENT",
  "UPDATE_APPOINTMENT",
  "DELETE_APPOINTMENT",
  "GET_BOOKING",
  "DELETE_BOOKING",

  // CONTENT
  "CREATE_CONTENT",
  "UPDATE_CONTENT",
  "DELETE_CONTENT",
  "GET_CONTENTS",

  // SCHEDULE
  "GET_ALL_SCHEDULE",
  "GET_GROUP_INFO",
  "GET_COMMENT_GROUP_ID",
  "GET_AGENDA_GROUP_ID",

  //DISCOUNTS
  "CREATE_DISCOUNTS",
  "UPDATE_DISCOUNTS",
  "DELETE_DISCOUNTS",
  "GET_DISCOUNTS",

  // MEANS MARKETING CONTACTS
  "GET_MARKETING_CONTACTS",
  "GET_MARKETING_CONTACT_DETAIL",
  "EDIT_MARKETING_CONTACT",
  "CREATE_MARKETING_CONTACT",
  "DELETE_MARKETING_CONTACT",

  // MEANS: Marketing list

  "GET_MARKETING_LISTS",
  "GET_MARKETING_LIST_DETAIL",
  "GET_MARKETING_LIST_BY_FOLDER",
  "EDIT_MARKETING_LIST",
  "CREATE_MARKETING_LIST",
  "DELETE_MARKETING_LIST",

  "GET_CONTACT_IN_LIST",

  // MEANS: marketing folder
  "GET_MARKETING_FOLDERS",
  "GET_MARKETING_FOLDER_DETAIL",
  "EDIT_MARKETING_FOLDER",
  "CREATE_MARKETING_FOLDER",
  "DELETE_MARKETING_FOLDER",

  //note : get all folder -> handle list select

  "GET_MARKETING_FOLDERS_SELECT",
  //space
  "GET_MARKETING_CONTACTS_SELECT",

  "ADD_CONTACTS_TO_LIST",
  "DELETE_CONTACTS_TO_LIST",

  // MEANS MARKETING EMAIL
  "GET_MARKETING_EMAILS",
  "GET_MARKETING_EMAIL_DETAIL",
  //space
  "EDIT_MARKETING_EMAIL",
  "CREATE_MARKETING_EMAIL",
  "CREATE_MARKETING_EMAIL_NOW",
  "DELETE_MARKETING_EMAIL",

  "GET_MARKETING_EMAIL_SENDERS",
  "GET_MARKETING_EMAIL_TEMPLATES",
  "GET_MARKETING_EMAIL_RECIPIENTS",
  /** DASHBOARD */
  "GET_TIMELINES_STATISTICS",
  "GET_ALL_TIMELINES",

  "SET_CURRENT_FOLDER",
  "IMPORT_CONTACT",
  "EXPORT_CONTACT",
  "CLEAR_LIST",

  /* SLIDE */
  "CREATE_SLIDE",
  "GET_ALL_SLIDE",
  "GET_DETAIL_SLIDE",
  "UPDATE_SLIDE",
  "DELETE_SLIDE",

  "GET_CUSTOMER_INFO",
  "UPDATE_TRANSACTION_TEACHER",
  "SET_ORDER_SLICE",

  "SET_SLICE",
  "SHOW_INTERNSHIP_LIST",

  /** BLOG */
  "GET_LIST_CATEGORIES_BLOG",
  "CREATE_CATEGORY_BLOG",
  "UPDATE_CATEGORY_BLOG",
  "DELETE_CATEGORY_BLOG",
  "GET_LIST_BLOGS",
  "CREATE_BLOG",
  "UPDATE_BLOG",
  "DELETE_BLOG",
];
