import { put } from "redux-saga/effects";
import { push } from "connected-react-router";
import LocalStorage from "./storage";
import Notification from "../components/notification";
import language from "../languages/language";

export default function sagaRun({ api }) {
  return function* ({ type, data, callback }) {
    const successType = `${type}_SUCCESS`;
    const failureType = `${type}_FAILURE`;

    try {
      yield put({ type: `${type}_REQUEST`, payload: data });
      const res = yield api(data);
      if (res.status && res.status === 200) {
        yield put({ type: successType, data: res.data, payload: data });
        if (callback) {
          callback(successType, res.data);
        }
      } else {
        throw res.errors;
      }
    } catch (res) {
      // HANDEL ERRelse
      if (res?.status === 401 || res?.status === 403) {
        LocalStorage.clear();
        return yield put(push("/login"));
      }

      Notification.error(
        language.find((lan) => lan.code === res?.data?.code)?.language ||
          res?.data?.code.split("_").join(" ") ||
          "FAILURE",
      );

      yield put({ type: failureType, data: res?.data, payload: data });
      if (callback) {
        callback(failureType, res?.data);
      }
    }
  };
}
