import { Component } from "react";
import { connect } from "react-redux";
import { actions } from "../store/actions";
import Storage from "../utils/storage";

class Settings extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const { getInfoFirstLoad, getCenters } = this.props;
    getInfoFirstLoad(null, (action) => {
      if (Storage.has("ACCESS_TOKEN")) {
        getCenters(null, () => {
          this.setState({
            loading: false,
          });
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  render() {
    const { loading } = this.state;
    const { children } = this.props;

    return loading ? null : children;
  }
}

export default connect(null, {
  getInfoFirstLoad: actions.getInfoFirstLoad,
  getCenters: actions.getCenters,
})(Settings);
