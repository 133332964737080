import { MainApi } from "./endpoint";
// import { mockData } from '../utils/tools'

export const createContent = (payload) =>
  MainApi.post("/admin/contents", payload);
export const getContents = (payload) => MainApi.get("/admin/contents", payload);
export const updateContent = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;
  return MainApi.update(`/admin/contents/${payload.id}`, handlePayload);
};
export const deleteContent = (payload) =>
  MainApi.delete(`/admin/contents/${payload.id}`);
