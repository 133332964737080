import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,

  scheduleList: [],
  groupInfo: null,

  listAgenda: [],
  countAgenda: 0,

  comments: null,
  countComment: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_ALL_SCHEDULE_REQUEST:
    case TYPES.GET_COMMENT_GROUP_ID_REQUEST:
    case TYPES.GET_AGENDA_GROUP_ID_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.GET_GROUP_INFO_REQUEST:
      return {
        ...state,
        submitting: action.type,
        groupInfo: null,
      };
    case TYPES.GET_ALL_SCHEDULE_SUCCESS:
      return {
        ...state,
        submitting: null,

        scheduleList: action.data,
      };
    case TYPES.GET_GROUP_INFO_SUCCESS:
      return {
        ...state,
        submitting: null,
        groupInfo: action.data,
      };
    case TYPES.GET_COMMENT_GROUP_ID_SUCCESS:
      return {
        ...state,
        submitting: null,
        comments: action.data?.rows,
        countComment: action.data.count,
      };
    case TYPES.GET_AGENDA_GROUP_ID_SUCCESS:
      return {
        ...state,
        submitting: null,
        listAgenda: action.data.rows,
        countAgenda: action.data.count,
      };
    case TYPES.GET_ALL_SCHEDULE_FAILURE:
    case TYPES.GET_GROUP_INFO_FAILURE:
    case TYPES.GET_COMMENT_GROUP_ID_FAILURE:
    case TYPES.GET_AGENDA_GROUP_ID_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
