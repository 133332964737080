import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,
  categories: [],

  blogs: [],
  countBlog: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.DELETE_CATEGORY_BLOG_REQUEST:
    case TYPES.CREATE_CATEGORY_BLOG_REQUEST:
    case TYPES.UPDATE_CATEGORY_BLOG_REQUEST:
    case TYPES.GET_LIST_CATEGORIES_BLOG_REQUEST:
    case TYPES.GET_LIST_BLOGS_REQUEST:
    case TYPES.CREATE_BLOG_REQUEST:
    case TYPES.UPDATE_BLOG_REQUEST:
    case TYPES.DELETE_BLOG_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.GET_LIST_CATEGORIES_BLOG_SUCCESS:
      return {
        ...state,
        submitting: null,

        categories: action.data,
      };
    case TYPES.DELETE_CATEGORY_BLOG_SUCCESS:
      return {
        ...state,
        submitting: null,

        categories: state.categories.filter(
          (item) => item.id !== action.payload.id,
        ),
      };
    case TYPES.CREATE_CATEGORY_BLOG_SUCCESS:
      return {
        ...state,
        submitting: null,

        categories: [action.data, ...state.categories],
      };
    case TYPES.UPDATE_CATEGORY_BLOG_SUCCESS:
      return {
        ...state,
        submitting: null,

        categories: state.categories.map((item) =>
          item.id === action.payload.id
            ? {
                ...item,
                ...action.payload,
              }
            : item,
        ),
      };
    case TYPES.GET_LIST_BLOGS_SUCCESS:
      return {
        ...state,
        submitting: null,

        blogs: action.data.rows,
        countBlog: action.data.count,
      };
    case TYPES.CREATE_BLOG_SUCCESS:
    case TYPES.UPDATE_BLOG_SUCCESS:
    case TYPES.DELETE_BLOG_SUCCESS:
      return {
        ...state,
        submitting: null,
      };
    case TYPES.CREATE_BLOG_FAILURE:
    case TYPES.UPDATE_BLOG_FAILURE:
    case TYPES.DELETE_BLOG_FAILURE:
    case TYPES.GET_LIST_BLOGS_FAILURE:
    case TYPES.DELETE_CATEGORY_BLOG_FAILURE:
    case TYPES.GET_LIST_CATEGORIES_BLOG_FAILURE:
    case TYPES.CREATE_CATEGORY_BLOG_FAILURE:
    case TYPES.UPDATE_CATEGORY_BLOG_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
