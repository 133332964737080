import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,

  subjects: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_SUBJECTS_REQUEST:
    case TYPES.UPDATE_SUBJECT_REQUEST:
    case TYPES.DELETE_SUBJECT_REQUEST:
    case TYPES.CREATE_SUBJECT_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        submitting: null,

        subjects: [action.data, ...state.subjects],
      };
    case TYPES.UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        submitting: null,

        subjects: state.subjects.map((sub) =>
          sub.id === action.payload.id ? action.payload : sub,
        ),
      };
    case TYPES.DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        submitting: null,

        subjects: state.subjects.filter((sub) => sub.id !== action.data.id),
      };
    case TYPES.GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        submitting: null,

        subjects: action.data,
      };
    case TYPES.CREATE_SUBJECT_FAILURE:
    case TYPES.UPDATE_SUBJECT_FAILURE:
    case TYPES.DELETE_SUBJECT_FAILURE:
    case TYPES.GET_SUBJECTS_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
