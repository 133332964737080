import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  getAllSlide,
  getDetailSlide,
  createSlide,
  updateSlide,
  deleteSlide,
  setOrderSlice,
} from "../../api/slide";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.SET_ORDER_SLICE, sagaRunning({ api: setOrderSlice })),
    takeLatest(TYPES.GET_ALL_SLIDE, sagaRunning({ api: getAllSlide })),
    takeLatest(TYPES.GET_DETAIL_SLIDE, sagaRunning({ api: getDetailSlide })),
    takeLatest(TYPES.CREATE_SLIDE, sagaRunning({ api: createSlide })),
    takeLatest(TYPES.UPDATE_SLIDE, sagaRunning({ api: updateSlide })),
    takeLatest(TYPES.DELETE_SLIDE, sagaRunning({ api: deleteSlide })),
  ]);
}
