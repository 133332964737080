import { MainApi } from "./endpoint";
// import { mockData } from '../utils/tools'

export const createRoom = (payload) => MainApi.post("/admin/rooms", payload);
export const updateRoom = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;
  return MainApi.put(`/admin/rooms/${payload.id}`, handlePayload);
};
export const deleteRoom = (payload) =>
  MainApi.delete(`/admin/rooms/${payload.id}`);
export const getRooms = (payload) =>
  MainApi.get(`/admin/rooms/center/${payload.id}`);
