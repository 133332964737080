import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "../serviceWorker";
import Store from "../store";
import Routes from "./routes";
import Settings from "./settings";

import "../languages/index";

// import '../index.css';
import "antd/dist/antd.css";
import "../App.css";

// handleAxiosApi();
ReactDOM.render(
  <React.Fragment>
    <Store>
      <Settings>
        <Routes />
      </Settings>
    </Store>
  </React.Fragment>,
  document.getElementById("root"),
);

serviceWorker.unregister();
