import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  createDiscounts,
  getDiscounts,
  updateDiscounts,
  deleteDiscounts,
} from "../../api/discounts";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.CREATE_DISCOUNTS, sagaRunning({ api: createDiscounts })),
    takeLatest(TYPES.UPDATE_DISCOUNTS, sagaRunning({ api: updateDiscounts })),
    takeLatest(TYPES.DELETE_DISCOUNTS, sagaRunning({ api: deleteDiscounts })),
    takeLatest(TYPES.GET_DISCOUNTS, sagaRunning({ api: getDiscounts })),
  ]);
}
