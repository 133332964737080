import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import CONSTANT from "../../constant";
import ImageLogo from "../../media/image/logo-full.png";
import ImageProfile from "../../media/image/profile-avatar.png";

import "./style.scss";
import { actions } from "../../store/actions";

const routerArray = [
  { name: "Tableau de bord", router: "/" },
  { name: "Centre de gestion", router: "/centers-management" },
  { name: "Contenu disponible", router: "/available-content" },
  { name: "Stage ouvert", router: "/internship" },
  { name: "Marketing", router: "/marketing" },
  { name: "Gestion des comptes", router: "/account-management" },
  { name: "Diapositive", router: "/slide" },
  { name: "Blog", router: "/blog-management" },
];
const routerBusinessOnly = [
  { name: "Centre", router: "/center" },
  { name: "Directeur", router: "/manager" },
  { name: "Contacter", router: "/contact" },
  { name: "Promo", router: "/discount" },
];

const SideBar = ({
  uiStore: { openSideBar },
  accountStore: { roleName, infoUser },
  setTab,
}) => {
  const resetTab = () => {
    setTab("0");
  };

  return (
    <div className={classNames("side-bar", { open: openSideBar })}>
      <div className="side-open">
        <div className="top-logo">
          <div className="logo">
            <img src={ImageLogo} alt="" />
          </div>
        </div>
        <div className="top-avatar">
          <div className="avatar">
            <img
              src={infoUser?.avatarUrl ? infoUser.avatarUrl : ImageProfile}
              alt=""
            />
          </div>
          <div className="name">{`${infoUser.firstName} ${infoUser.lastName}`}</div>
        </div>
        <div className="body-route">
          {routerArray.map((route, index) => (
            <NavLink exact className="link" key={index} to={route.router}>
              <span className="route-name" onClick={resetTab}>
                {route.name}
              </span>
            </NavLink>
          ))}
        </div>
        {roleName === CONSTANT.ROLE.OWNER && (
          <>
            <div className="business">
              Pour les propriétaires d'entreprises uniquement
            </div>
            <div className="body-route">
              {routerBusinessOnly.map((route, index) => (
                <NavLink exact className="link" key={index} to={route.router}>
                  <span className="route-name" onClick={resetTab}>
                    {route.name}
                  </span>
                </NavLink>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    uiStore: state.ui,
    accountStore: state.account,
  }),
  {
    setTab: actions.setTab,
  },
)(SideBar);
