import { convertCamelCase } from "../../utils/tools";
import types from "./types";

const TYPES = types.reduce((accumulator, currentValue) => {
  const typeSuccess = `${currentValue}_SUCCESS`;
  const typeRequest = `${currentValue}_REQUEST`;
  const typeFailure = `${currentValue}_FAILURE`;

  accumulator[currentValue] = currentValue;
  accumulator[typeSuccess] = typeSuccess;
  accumulator[typeRequest] = typeRequest;
  accumulator[typeFailure] = typeFailure;
  return accumulator;
}, {});

const actions = types.reduce((accumulator, currentValue) => {
  accumulator[convertCamelCase(currentValue)] = (data, callback) => ({
    type: currentValue,
    data,
    callback,
  });
  return accumulator;
}, {});

export default TYPES;
export { TYPES, actions };
