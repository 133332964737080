import { all, takeLatest, put } from "redux-saga/effects";
import { TYPES } from "../actions";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.TOGGLE_SIDE_BAR, toggleSideBar),
    takeLatest(TYPES.SET_TAB, setTab),
  ]);
}

function* toggleSideBar(data) {
  yield put({ type: TYPES.TOGGLE_SIDE_BAR_SUCCESS, data });
}

function* setTab(data) {
  yield put({ type: TYPES.SET_TAB_SUCCESS, data });
}
