import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,

  count: null,
  groups: [],
  groupDetail: null,

  internships: {
    count: 0,
    rows: [],
  },
  isRefresh: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_GROUPS_REQUEST:
    case TYPES.CREATE_GROUP_REQUEST:
    case TYPES.GET_GROUP_DETAIL_REQUEST:
    case TYPES.UPDATE_GROUP_REQUEST:
    case TYPES.DELETE_GROUP_REQUEST:
    case TYPES.GET_INTERNSHIPS_REQUEST:
    case TYPES.CREATE_INTERNSHIP_REQUEST:
    case TYPES.DELETE_INTERNSHIP_REQUEST:
    case TYPES.UPDATE_INTERNSHIP_REQUEST:
    case TYPES.SHOW_HOMEPAGE_REQUEST:
    case TYPES.SHOW_INTERNSHIP_LIST_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        submitting: null,

        groups: [action.data, ...state.groups],
        isRefresh: Date.now(),
      };
    case TYPES.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        submitting: null,

        groups: state.groups.map((gr) =>
          gr.id === action.data.id ? action.data : gr,
        ),
        groupDetail: action.data,
      };
    case TYPES.GET_GROUPS_SUCCESS:
      return {
        ...state,
        submitting: null,

        count: action.data?.count,
        groups: action.data.rows,
      };

    case TYPES.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        submitting: null,

        groups: state.groups.filter((r) => r.id !== action.payload.id),
      };
    case TYPES.GET_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        submitting: null,

        groupDetail: action.data,
      };

    case TYPES.GET_INTERNSHIPS_SUCCESS:
      return {
        ...state,
        submitting: null,

        internships: {
          rows: action.data.rows,
          count: action.data.count,
        },
      };
    case TYPES.CREATE_INTERNSHIP_SUCCESS:
      return {
        ...state,
        submitting: null,

        internships: {
          rows: [action.data, ...state.internships.rows],
          count: state.internships.count + 1,
        },
      };
    case TYPES.DELETE_INTERNSHIP_SUCCESS:
      return {
        ...state,
        submitting: null,

        internships: {
          rows: state.internships.rows.filter(
            (inte) => inte.id !== action.payload.id,
          ),
          count: state.internships.count - 1,
        },
      };
    case TYPES.UPDATE_INTERNSHIP_SUCCESS:
      return {
        ...state,
        submitting: null,

        internships: {
          ...state.internships,
          rows: state.internships.rows.map((inte) =>
            inte.id === action.payload.id ? action.data : inte,
          ),
        },
      };
    case TYPES.SHOW_HOMEPAGE_SUCCESS:
      return {
        ...state,
        submitting: null,

        internships: {
          ...state.internships,
          rows: state.internships.rows.map((inte) =>
            inte.id === action.payload.id
              ? { ...inte, showHomepage: action.payload.showHomepage }
              : inte,
          ),
        },
      };
    case TYPES.SHOW_INTERNSHIP_LIST_SUCCESS:
      return {
        ...state,
        submitting: null,

        internships: {
          ...state.internships,
          rows: state.internships.rows.map((inte) =>
            inte.id === action.payload.id
              ? { ...inte, showInternship: !inte.showInternship }
              : inte,
          ),
        },
      };
    case TYPES.SHOW_INTERNSHIP_LIST_FAILURE:
    case TYPES.SHOW_HOMEPAGE_FAILURE:
    case TYPES.GET_INTERNSHIPS_FAILURE:
    case TYPES.CREATE_INTERNSHIP_FAILURE:
    case TYPES.DELETE_INTERNSHIP_FAILURE:
    case TYPES.UPDATE_INTERNSHIP_FAILURE:
    case TYPES.GET_GROUP_DETAIL_FAILURE:
    case TYPES.CREATE_GROUP_FAILURE:
    case TYPES.GET_GROUPS_FAILURE:
    case TYPES.UPDATE_GROUP_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
