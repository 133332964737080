import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,

  contents: [],
  count: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_CONTENTS_REQUEST:
    case TYPES.CREATE_CONTENT_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.CREATE_CONTENT_SUCCESS:
      return {
        ...state,
        submitting: null,

        // contents: [
        //   action.payload,
        //   ...state.contents
        // ],
        // count: state.count + 1
      };
    case TYPES.UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        submitting: null,

        contents: state.contents.map((sub) =>
          sub.id === action.data.id ? action.data : sub,
        ),
      };
    case TYPES.DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        submitting: null,

        contents: state.contents.filter((sub) => sub.id !== action.data.id),
      };
    case TYPES.GET_CONTENTS_SUCCESS:
      return {
        ...state,
        submitting: null,

        contents: action.data.rows,
        count: action.data.count,
      };
    case TYPES.CREATE_CONTENT_FAILURE:
    case TYPES.UPDATE_CONTENT_FAILURE:
    case TYPES.DELETE_CONTENT_FAILURE:
    case TYPES.GET_CONTENTS_FAILURE:
      return {
        ...state,
        error: action.error,

        submitting: null,
      };
    default:
      return state;
  }
};
