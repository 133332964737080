import { message } from "antd";
import "antd/es/message/style/css";

import "./style.scss";

class NotificationApi {
  static success(text) {
    message.success(text, 1.5);
  }

  static warning(text) {
    message.warning(text, 1.5);
  }

  static error(text) {
    message.error(text, 2.5);
  }
}

export default NotificationApi;
