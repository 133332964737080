import { TYPES } from "store/actions";

const initialState = {
  rContacts: [],
  rEmails: [],
  rSenders: [],
  rTemplates: [],
  rRecipients: [],
  rLists: {
    lists: [],
    count: 0,
  },
  rContactInList: [],
  rFolders: {
    folders: [],
    count: 0,
  },
  currentFolder: "all",
  rFoldersSelect: [],
  rContactsSelect: [],
  rDetails: null,
  loading: null,
  spinning: null,

  submitting: null,
};

export default (state = initialState, { type, data, payload }) => {
  switch (type) {
    case TYPES.GET_MARKETING_EMAILS_REQUEST:
    case TYPES.GET_MARKETING_EMAIL_SENDERS_REQUEST:
    case TYPES.GET_MARKETING_EMAIL_TEMPLATES_REQUEST:
    case TYPES.GET_MARKETING_EMAIL_RECIPIENTS_REQUEST:
    case TYPES.GET_MARKETING_CONTACTS_REQUEST:
    case TYPES.GET_MARKETING_LISTS_REQUEST:
    case TYPES.GET_CONTACT_IN_LIST_REQUEST:
    case TYPES.GET_MARKETING_FOLDERS_REQUEST:
    case TYPES.GET_MARKETING_LIST_BY_FOLDER_REQUEST:
    case TYPES.IMPORT_CONTACT_REQUEST:
    case TYPES.EXPORT_CONTACT_REQUEST:
      return {
        ...state,
        loading: type,
      };
    // list emails
    case TYPES.GET_MARKETING_EMAILS_SUCCESS:
      return {
        ...state,
        loading: null,
        rEmails: data,
      };
    // list senders
    case TYPES.GET_MARKETING_EMAIL_SENDERS_SUCCESS:
      return {
        ...state,
        loading: null,
        rSenders: data,
      };
    // list templates
    case TYPES.GET_MARKETING_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: null,
        rTemplates: data,
      };
    // list recipients
    case TYPES.GET_MARKETING_EMAIL_RECIPIENTS_SUCCESS:
      return {
        ...state,
        loading: null,
        rRecipients: data,
      };

    case TYPES.GET_MARKETING_LIST_BY_FOLDER_SUCCESS:
    case TYPES.GET_MARKETING_LISTS_SUCCESS:
      return {
        ...state,
        loading: null,
        rLists: data.count
          ? data
          : {
              lists: [],
              count: 0,
            },
      };

    case TYPES.GET_MARKETING_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: null,
        rContacts: data,
      };

    case TYPES.GET_CONTACT_IN_LIST_SUCCESS:
      return {
        ...state,
        loading: null,
        rContactInList: data,
      };
    case TYPES.GET_MARKETING_FOLDERS_SUCCESS:
      return {
        ...state,
        loading: null,
        rFolders: data,
      };

    case TYPES.GET_MARKETING_EMAIL_DETAIL_REQUEST:
    case TYPES.EDIT_MARKETING_EMAIL_REQUEST:
    case TYPES.DELETE_MARKETING_EMAIL_REQUEST:
    case TYPES.CREATE_MARKETING_EMAIL_REQUEST:
    case TYPES.CREATE_MARKETING_EMAIL_NOW_REQUEST:
      return {
        ...state,
        spinning: type,
      };

    case TYPES.GET_MARKETING_CONTACT_DETAIL_REQUEST:
    case TYPES.EDIT_MARKETING_CONTACT_REQUEST:
    case TYPES.DELETE_MARKETING_CONTACT_REQUEST:
    case TYPES.CREATE_MARKETING_CONTACT_REQUEST:
      return {
        ...state,
        spinning: type,
      };

    case TYPES.GET_MARKETING_LIST_DETAIL_REQUEST:
    case TYPES.DELETE_MARKETING_LIST_REQUEST:
    case TYPES.EDIT_MARKETING_LIST_REQUEST:
    case TYPES.CREATE_MARKETING_LIST_REQUEST:
      return {
        ...state,
        spinning: type,
      };

    case TYPES.CREATE_MARKETING_FOLDER_REQUEST:
    case TYPES.DELETE_MARKETING_FOLDER_REQUEST:
    case TYPES.EDIT_MARKETING_FOLDER_REQUEST:
      return {
        ...state,
        spinning: type,
      };

    case TYPES.CREATE_MARKETING_EMAIL_SUCCESS:
    case TYPES.CREATE_MARKETING_EMAIL_NOW_SUCCESS:
    case TYPES.DELETE_MARKETING_EMAIL_SUCCESS:
    case TYPES.EDIT_MARKETING_EMAIL_SUCCESS:
      return {
        ...state,
        spinning: type,
      };
    case TYPES.GET_MARKETING_EMAIL_DETAIL_SUCCESS:
      return {
        ...state,
        spinning: type,
        loading: null,
        rDetails: data,
      };
    // case TYPES.EDIT_MARKETING_CONTACT_SUCCESS:
    // case TYPES.DELETE_MARKETING_CONTACT_SUCCESS:

    case TYPES.GET_MARKETING_CONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        spinning: null,
        loading: null,
        rDetails: data,
      };
    case TYPES.EDIT_MARKETING_CONTACT_SUCCESS:
    case TYPES.DELETE_MARKETING_CONTACT_SUCCESS:
    case TYPES.CREATE_MARKETING_CONTACT_SUCCESS:
    case TYPES.GET_MARKETING_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        spinning: null,
        rDetails: data,
      };
    case TYPES.EDIT_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        spinning: null,
        rLists: {
          ...state.rLists,
          lists: state.rLists.lists.map((f) =>
            f.id === payload.currentId
              ? { ...f, name: payload.values.name, id: payload.currentId }
              : f,
          ),
        },
      };
    case TYPES.DELETE_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        spinning: null,
        rLists: {
          ...state.rLists,
          lists: state.rLists.lists.filter((l) => l.id !== payload),
          count: state.rLists.count - 1,
        },
      };
    case TYPES.CREATE_MARKETING_LIST_SUCCESS:
      return {
        ...state,
        spinning: null,
        rLists: {
          ...state.rLists,
          lists:
            state.currentFolder === "all" ||
            payload.folderId === state.currentFolder
              ? [
                  {
                    id: data.id,
                    name: payload.name,
                    totalSubscribers: 0,
                    totalBlacklisted: 0,
                  },
                  ...state.rLists.lists,
                ]
              : state.rLists.lists,
          count: state.rLists.count + 1,
        },
      };
    // space
    case TYPES.EDIT_MARKETING_FOLDER_SUCCESS:
      return {
        ...state,
        spinning: null,
        rFolders: {
          ...state.rFolders,
          folders: state.rFolders.folders.map((f) =>
            f.id === payload.currentId
              ? { name: payload.values.name, id: payload.currentId }
              : f,
          ),
        },
      };
    case TYPES.DELETE_MARKETING_FOLDER_SUCCESS:
      return {
        ...state,
        spinning: null,
        rFolders: {
          ...state.rFolders,
          folders: state.rFolders.folders.filter((f) => f.id !== payload),
          count: state.rFolders.count - 1,
        },
      };
    case TYPES.CREATE_MARKETING_FOLDER_SUCCESS:
      return {
        ...state,
        spinning: null,
        rFolders: {
          ...state.rFolders,
          folders: [
            { id: data.id, name: payload.name },
            ...state.rFolders.folders,
          ],
          count: state.rFolders.count + 1,
        },
      };
    case TYPES.GET_MARKETING_EMAILS_FAILURE:
    case TYPES.GET_MARKETING_EMAIL_SENDERS_FAILURE:
    case TYPES.GET_MARKETING_EMAIL_TEMPLATES_FAILURE:
    case TYPES.GET_MARKETING_EMAIL_RECIPIENTS_FAILURE:
    case TYPES.GET_MARKETING_EMAIL_DETAIL_FAILURE:
    case TYPES.DELETE_MARKETING_EMAIL_FAILURE:
    case TYPES.CREATE_MARKETING_EMAIL_FAILURE:
    case TYPES.CREATE_MARKETING_EMAIL_NOW_FAILURE:
    case TYPES.GET_MARKETING_CONTACTS_FAILURE:
    case TYPES.GET_MARKETING_CONTACT_DETAIL_FAILURE:
    case TYPES.EDIT_MARKETING_CONTACT_FAILURE:
    case TYPES.DELETE_MARKETING_CONTACT_FAILURE:
    case TYPES.CREATE_MARKETING_CONTACT_FAILURE:
    case TYPES.GET_MARKETING_LIST_DETAIL_FAILURE:
    case TYPES.EDIT_MARKETING_LIST_FAILURE:
    case TYPES.DELETE_MARKETING_LIST_FAILURE:
    case TYPES.CREATE_MARKETING_LIST_FAILURE:
    case TYPES.GET_CONTACT_IN_LIST_FAILURE:
    case TYPES.GET_MARKETING_FOLDERS_FAILURE:
    case TYPES.DELETE_MARKETING_FOLDER_FAILURE:
    case TYPES.CREATE_MARKETING_FOLDER_FAILURE:
    case TYPES.EDIT_MARKETING_FOLDER_FAILURE: {
      return {
        ...state,
        loading: null,
        spinning: null,
      };
    }

    // note: logic handle select folder in  FOLDERS_SELECT list

    case TYPES.GET_MARKETING_FOLDERS_SELECT_REQUEST:
      return {
        ...state,
        spinning: type,
      };
    case TYPES.GET_MARKETING_FOLDERS_SELECT_SUCCESS:
      return {
        ...state,
        spinning: null,
        rFoldersSelect: data,
      };

    case TYPES.GET_MARKETING_FOLDERS_SELECT_FAILURE:
      return {
        ...state,
        spinning: null,
      };
    //space
    case TYPES.GET_MARKETING_CONTACTS_SELECT_REQUEST:
      return {
        ...state,
        spinning: type,
      };

    case TYPES.GET_MARKETING_CONTACTS_SELECT_SUCCESS:
      return {
        ...state,
        spinning: null,
        rContactsSelect: data,
      };

    case TYPES.GET_MARKETING_CONTACTS_SELECT_FAILURE:
      return {
        ...state,
        spinning: null,
      };
    //note: Add existing contacts to a list

    case TYPES.ADD_CONTACTS_TO_LIST_REQUEST:
    case TYPES.DELETE_CONTACTS_TO_LIST_REQUEST:
      return {
        ...state,
        spinning: type,
      };

    case TYPES.ADD_CONTACTS_TO_LIST_SUCCESS:
    case TYPES.DELETE_CONTACTS_TO_LIST_SUCCESS:
      return {
        ...state,
        spinning: null,
      };

    case TYPES.ADD_CONTACTS_TO_LIST_FAILURE:
    case TYPES.DELETE_CONTACTS_TO_LIST_FAILURE:
      return {
        ...state,
        spinning: null,
      };
    case TYPES.IMPORT_CONTACT_SUCCESS:
    case TYPES.IMPORT_CONTACT_FAILURE:
    case TYPES.EXPORT_CONTACT_SUCCESS:
    case TYPES.EXPORT_CONTACT_FAILURE:
    case TYPES.GET_MARKETING_LIST_BY_FOLDER_FAILURE:
      return {
        ...state,
        loading: null,
      };

    //note: end ---- Add existing contacts to a list
    case TYPES.SET_CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: data.id,
      };
    case TYPES.CLEAR_LIST:
      return {
        ...state,
        rLists: {
          lists: [],
          count: 0,
        },
      };
    default:
      return state;
  }
};
