import React, { Component } from "react";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { actions, TYPES } from "../../store/actions";
import Popover from "../popover";
import { withRouter } from "react-router-dom";
import LocalStorage from "../../utils/storage";
import Notification from "../notification";
import Select from "../select";
import CONSTANT from "../../constant";
import ImageAvatar from "../../media/image/avatar-profile-white.png";
import ChangePassword from "../../pages/account/change-password";
import UpdateAvatar from "../../pages/account/profile";

import "./style.scss";

class Header extends Component {
  componentDidMount() {
    // const { getCenters } = this.props
    // getCenters()
  }

  onLogout = () => {
    const { logout, history } = this.props;
    logout(null, (action) => {
      if (action === TYPES.LOGOUT_SUCCESS) {
        LocalStorage.clear();
        Notification.success("Logout success");
        return history.push("/login");
      }
    });
  };

  render() {
    const {
      uiStore: { openSideBar },
      accountStore: { roleName, infoUser },
      centersStore: { centers, currentCenter },
      selectCenter,
      toggleSideBar,
    } = this.props;

    const contentAccount = (
      <div className="content-account">
        <div onClick={() => this.updateProfile.open()} className="link">
          Modifier le profil
        </div>
        <div onClick={() => this.changePass.open()} className="link">
          Changer le mot de passe
        </div>
        <div onClick={this.onLogout} className="link">
          Déconnexion
        </div>
      </div>
    );

    return (
      <div className="header">
        <div className="left">
          <div className="toggle-menu">
            {openSideBar ? (
              <MenuFoldOutlined
                className="icon-toggle"
                onClick={() => toggleSideBar()}
              />
            ) : (
              <MenuUnfoldOutlined
                className="icon-toggle"
                onClick={() => toggleSideBar()}
              />
            )}
          </div>
          <div className="box-select-center">
            {/* { */}
            {/* roleName === CONSTANT.ROLE.OWNER && ( */}
            <Select
              options={centers}
              optionBinding={{
                name: "name",
                value: "id",
              }}
              disabled={roleName !== CONSTANT.ROLE.OWNER}
              value={currentCenter}
              className="select-center"
              onChange={(e) => {
                LocalStorage.set("CURRENT_CENTER", e.target.value);
                selectCenter(e.target.value);
              }}
            />
            {/* ) */}
            {/* } */}
          </div>
        </div>
        <div className="right">
          <Popover
            content={contentAccount}
            className="popover-account"
            placement="bottomRight"
          >
            <div className="logo">
              <img
                src={infoUser.avatar ? infoUser.avatar : ImageAvatar}
                alt=""
              />
            </div>
          </Popover>
        </div>
        <ChangePassword
          innerRef={(ref) => {
            this.changePass = ref;
          }}
        />
        <UpdateAvatar
          innerRef={(ref) => {
            this.updateProfile = ref;
          }}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    uiStore: state.ui,
    accountStore: state.account,
    centersStore: state.centers,
  }),
  {
    toggleSideBar: actions.toggleSideBar,
    logout: actions.logout,
    getCenters: actions.getCenters,
    selectCenter: actions.selectCenter,
  },
)(withRouter(Header));
