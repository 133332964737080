import { TYPES } from "../actions";

const INIT_STATE = {
  openSideBar: true,
  submitting: null,

  count: null,
  contacts: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.UPLOAD_FILE_REQUEST:
    case TYPES.GET_CONTACTS_REQUEST:
    case TYPES.DELETE_CONTACT_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        submitting: null,
      };

    case TYPES.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        submitting: null,

        count: action.data?.count,
        contacts: action.data?.rows,
      };
    case TYPES.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        submitting: null,

        contacts: state.contacts.filter((a) => a.id !== action.payload.id),
      };
    case TYPES.DELETE_CONTACT_FAILURE:
    case TYPES.UPLOAD_FILE_FAILURE:
    case TYPES.GET_CONTACTS_FAILURE:
      return {
        ...state,
        submitting: null,
      };
    default:
      return state;
  }
};
