import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  getCenters,
  createCenter,
  updateCenter,
  deleteCenter,
  getTimelinesStatistics,
  getAllTimelines,
  createHoliday,
  cancelHoliday,
} from "../../api/centers";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.CREATE_HOLIDAY, sagaRunning({ api: createHoliday })),
    takeLatest(TYPES.CANCEL_HOLIDAY, sagaRunning({ api: cancelHoliday })),
    takeLatest(TYPES.GET_ALL_TIMELINES, sagaRunning({ api: getAllTimelines })),
    takeLatest(
      TYPES.GET_TIMELINES_STATISTICS,
      sagaRunning({ api: getTimelinesStatistics }),
    ),
    takeLatest(TYPES.GET_CENTERS, sagaRunning({ api: getCenters })),
    takeLatest(TYPES.CREATE_CENTER, sagaRunning({ api: createCenter })),
    takeLatest(TYPES.UPDATE_CENTER, sagaRunning({ api: updateCenter })),
    takeLatest(TYPES.DELETE_CENTER, sagaRunning({ api: deleteCenter })),
  ]);
}
