import { TYPES } from "../actions";
import CONSTANT from "../../constant";
import LocalStorage from "../../utils/storage";

const INIT_STATE = {
  submitting: null,
  error: null,

  centers: [],
  count: null,
  currentCenter: null,

  allTimelines: {
    limit: 0,
    count: 0,
    rows: [],
  },
  timelinesStatistics: {
    numOfStudents: 0,
    numOfGroups: 0,
    amountPaymentSuccess: 0,
    numOfParentRegister: 0,
    numOfStudentEnroll: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_CENTERS_REQUEST:
    case TYPES.CREATE_CENTER_REQUEST:
    case TYPES.UPDATE_CENTER_REQUEST:
    case TYPES.DELETE_CENTER_REQUEST:
    case TYPES.GET_TIMELINES_STATISTICS_REQUEST:
    case TYPES.GET_ALL_TIMELINES_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.GET_CENTERS_SUCCESS:
      return {
        ...state,
        submitting: null,

        centers: action.data.rows,
        currentCenter: LocalStorage.has("CURRENT_CENTER")
          ? LocalStorage.get("CURRENT_CENTER")
          : action.data.rows.length
            ? action.data.rows[0].id
            : state.currentCenter,
      };
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        submitting: null,

        currentCenter:
          action.data.roleName === CONSTANT.ROLE.MANAGER
            ? action.data.infoUser.center.id
            : state.currentCenter,
      };
    case TYPES.GET_INFO_FIRST_LOAD_SUCCESS:
      return {
        ...state,
        submitting: null,

        currentCenter: action.data.currentCenter || state.currentCenter,
      };
    case TYPES.CREATE_CENTER_SUCCESS:
      return {
        ...state,
        submitting: null,

        // centers: [
        //   action.data,
        //   ...state.centers
        // ]
      };
    case TYPES.UPDATE_CENTER_SUCCESS:
      return {
        ...state,
        submitting: null,
      };
    case TYPES.DELETE_CENTER_SUCCESS:
      return {
        ...state,
        submitting: null,

        centers: state.centers.filter((c) => c.id !== action.payload.id),
        currentCenter:
          action.payload.id === state.currentCenter.id
            ? state.centers.length
              ? state.centers[0].id
              : null
            : state.currentCenter,
      };
    case TYPES.SELECT_CENTER:
      return {
        ...state,
        submitting: null,

        currentCenter: action.data,
      };

    case TYPES.GET_TIMELINES_STATISTICS_SUCCESS:
      return {
        ...state,
        submitting: null,

        timelinesStatistics: {
          numOfStudents: action.data.numOfStudents,
          numOfGroups: action.data.numOfGroups,
          amountPaymentSuccess: action.data.amountPaymentSuccess,
          numOfParentRegister: action.data.numOfParentRegister,
          numOfStudentEnroll: action.data.numOfStudentEnroll,
        },
      };
    case TYPES.GET_ALL_TIMELINES_SUCCESS:
      return {
        ...state,
        submitting: null,

        allTimelines: {
          ...state.allTimelines,
          limit: action.payload.limit,
          count: action.data.count,
          rows: action.data.rows,
        },
      };
    case TYPES.GET_TIMELINES_STATISTICS_FAILURE:
    case TYPES.GET_ALL_TIMELINES_FAILURE:
    case TYPES.GET_CENTERS_FAILURE:
    case TYPES.CREATE_CENTER_FAILURE:
    case TYPES.UPDATE_CENTER_FAILURE:
    case TYPES.DELETE_CENTER_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
