import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  createSubject,
  getSubjects,
  updateSubject,
  deleteSubject,
} from "../../api/subjects";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.CREATE_SUBJECT, sagaRunning({ api: createSubject })),
    takeLatest(TYPES.UPDATE_SUBJECT, sagaRunning({ api: updateSubject })),
    takeLatest(TYPES.DELETE_SUBJECT, sagaRunning({ api: deleteSubject })),
    takeLatest(TYPES.GET_SUBJECTS, sagaRunning({ api: getSubjects })),
  ]);
}
