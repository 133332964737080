import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  createGroup,
  updateGroup,
  getGroups,
  getGroupDetail,
  deleteGroup,
  getInternships,
  createInternship,
  updateInternship,
  deleteInternship,
  showHomepage,
  getAllGroups,
  moveStudentsNewGroup,
  showInternshipList,
} from "../../api/groups";

export default function* watcher() {
  yield all([
    takeLatest(
      TYPES.SHOW_INTERNSHIP_LIST,
      sagaRunning({ api: showInternshipList }),
    ),
    takeLatest(
      TYPES.GET_ALL_GROUPS_INTERNSHIP,
      sagaRunning({ api: getInternships }),
    ),
    takeLatest(
      TYPES.MOVE_STUDENTS_NEW_GROUP,
      sagaRunning({ api: moveStudentsNewGroup }),
    ),
    takeLatest(TYPES.GET_ALL_GROUPS, sagaRunning({ api: getAllGroups })),
    takeLatest(TYPES.SHOW_HOMEPAGE, sagaRunning({ api: showHomepage })),
    takeLatest(TYPES.GET_INTERNSHIPS, sagaRunning({ api: getInternships })),
    takeLatest(TYPES.CREATE_INTERNSHIP, sagaRunning({ api: createInternship })),
    takeLatest(TYPES.UPDATE_INTERNSHIP, sagaRunning({ api: updateInternship })),
    takeLatest(TYPES.DELETE_INTERNSHIP, sagaRunning({ api: deleteInternship })),
    takeLatest(TYPES.CREATE_GROUP, sagaRunning({ api: createGroup })),
    takeLatest(TYPES.UPDATE_GROUP, sagaRunning({ api: updateGroup })),
    takeLatest(TYPES.GET_GROUPS, sagaRunning({ api: getGroups })),
    takeLatest(TYPES.GET_GROUP_DETAIL, sagaRunning({ api: getGroupDetail })),
    takeLatest(TYPES.DELETE_GROUP, sagaRunning({ api: deleteGroup })),
  ]);
}
