import { MainApi } from "./endpoint";
// import { mockData } from '../utils/tools'

export const login = (payload) => MainApi.post("/admin/auth/login", payload);
export const logout = (payload) => MainApi.post("/admin/auth/logout", payload);
export const changePass = (payload) =>
  MainApi.put("/admin/us/change-password", payload);
export const updateProfile = (payload) => MainApi.put("/admin/us", payload);
export const sendMail = (payload) => MainApi.put("/admin/us", payload);
export const handleSendMail = (payload) =>
  MainApi.post("/admin/auth/send-email/forgot-password", payload);
export const resetPassword = (payload) =>
  MainApi.put("/admin/auth/change-password-by-token", payload);
export const getCustomerInfo = ({ id }) =>
  MainApi.get(`/admin/customers/${id}/detail`);
