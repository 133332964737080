export default [
  {
    code: "INVALID",
    language: "Invalide",
  },
  {
    code: "ADMIN_EXISTED",
    language: "Admin Existe",
  },
  {
    code: "SYSTEM_ERROR",
    language: "Erreur Système",
  },
  {
    code: "TEACHER_NOT_FOUND",
    language: "Professeur Non Trouvé",
  },
  {
    code: "ROOM_EXISTED",
    language: "Chambre Existe",
  },
  {
    code: "PARENT_NOT_FOUND",
    language: "Parent Non Trouvé",
  },
  {
    code: "STUDENT_NOT_FOUND",
    language: "Étudiant Non Trouvé",
  },
  {
    code: "OLD_PASSWORD_NOT_MATCH",
    language: "L'Ancien Mot De Passe Ne Correspond Pas",
  },
  {
    code: "EMAIL_EXISTED",
    language: "Email Existe",
  },
  {
    code: "CENTER_EXISTED",
    language: "Centre Existant",
  },
  {
    code: "PASSWORD_WRONG",
    language: "Mot De Passe Incorrect",
  },
  {
    code: "EMAIL_NOT_FOUND",
    language: "Email Non Trouvé",
  },
  {
    code: "NOT_CODE",
    language: "Pas De Code",
  },
  {
    code: "EXISTED",
    language: "Existé",
  },
  {
    code: "NOT_DEPOSIT",
    language: "Pas De Dépôt",
  },
  {
    code: "PHONE_NOT_ACTIVATED",
    language: "Phone Non Activé",
  },
  {
    code: "EMAIL_NOT_ACTIVATED",
    language: "Email Non Activé",
  },
  {
    code: "ADMIN_NOT_FOUND",
    language: "Admin Non Trouvé",
  },
  {
    code: "GROUP_NAME_EXISTED",
    language: "Nom Du Groupe Existe",
  },
  {
    code: "GROUP_START_DATE_MUST_GATHER_THAN_NOW",
    language: "La Date De Debut Du Groupe Doit Se Rassembler Que Maintenant",
  },
  {
    code: "TIME_OVERLAP",
    language: "Surcharge De Temps",
  },
  {
    code: "SUBJECT_NOT_FOUND",
    language: "Objet Non Trouvé",
  },
  {
    code: "USER_NOT_FOUND",
    language: "Utilisateur Non Trouvé",
  },
  {
    code: "SUBJECT_EXISTED",
    language: "Sujet Existé",
  },
  {
    code: "NOT_FOUND",
    language: "Pas Trouvé",
  },
  {
    code: "CENTER_NOT_FOUND",
    language: "Centre Non Trouvé",
  },
  {
    code: "STUDENT_COMPLICATED_OR_NOT_FOUND",
    language: "Étudiant Compliqué Ou Non Trouvé",
  },
  {
    code: "FIRST_ITEM_REPEATED_ON_NEED_TO_EQUAL_DAY_OF_START_DATE",
    language:
      "Premier Élément Répété Au Besoin D'Un Jour Égal À La Date De Début",
  },
  {
    code: "GROUP_NOT_FOUND",
    language: "Groupe Non Trouvé",
  },
  {
    code: "NUMBER_OF_STUDENT_MUST_BE_GATHER_THAN_REGISTERED",
    language: "Le Nombre D'Étudiants Doit Être Rassemblé Que Inscrit",
  },
  {
    code: "START_DATE_NEED_TO_BETWEEN_OLD_RANGE",
    language: "Date De Debut Besoin Entre L'Ancienne Gamme",
  },
  {
    code: "ROOM_NOT_FOUND",
    language: "Chambre Non Trouvée",
  },
  {
    code: "ROOM_NOT_BELONGS_TO_CENTER",
    language: "La Chambre N'Appartient Pas Au Centre",
  },
  {
    code: "USER_ENROLLED",
    language: "Utilisateur Inscrit",
  },
  {
    code: "GROUP_ENROLL_ERROR",
    language: "Erreur D'Inscription De Groupe",
  },
  {
    code: "TYPE_GROUP_NOT_SAME_INITIATION",
    language: "Groupe De Type Pas Même Initiation",
  },
  {
    code: "CART_EMPTY",
    language: "Panier Vide",
  },
  {
    code: "NUMBER_OF_ENROLLED_GROUP_INCORRECT",
    language: "Nombre De Groupe Inscrit Incorrect",
  },
  {
    code: "TEACHER_NOT_IN_CHARGE_OF_GROUP",
    language: "Enseignant Non En Charge Du Groupe",
  },
  {
    code: "INCLUDE_INVALID_STUDENT",
    language: "Inclure Un Étudiant Non Valide",
  },
  {
    code: "PRICE_NOT_UPDATED_ERROR",
    language: "Erreur De Prix Non Mis À Jour",
  },
  {
    code: "VERIFY_CODE_INVALID",
    language: "Verify invalid",
  },
  {
    code: "EMAIL_OR_PHONE_IN_USE",
    language: "Email Ou Téléphone Utilisés",
  },
  {
    code: "AMOUNT_NOT_NUMBER",
    language: "Montant Pas Numéro",
  },
  {
    code: "INVALID_AMOUNT",
    language: "Montant Non Valide",
  },
  {
    code: "INSERT_ATTACHMENT_FAIL",
    language: "Insérer Échec De La Pièce Jointe",
  },
  {
    code: "CALCULATE_AMOUNT_ERROR",
    language: "Erreur Calculer Montant",
  },
  {
    code: "FULL_SLOT",
    language: "Fente Complète",
  },
  {
    code: "PAYMENT_ERROR",
    language: "Erreur De Paiement",
  },
  {
    code: "COURSE_NOT_FOUND",
    language: "Cours Non Trouvé",
  },
  {
    code: "RECEIVER_NOT_NULL",
    language: "Recepteur Non Null",
  },
  {
    code: "CARD_DECLINE_ERROR",
    language: "Erreur De Refus De Carte",
  },
  {
    code: "PAYMENT_CURRING",
    language: "Courant De Paiement",
  },
  {
    code: "PAYMENT_ONETIME",
    language: "Paiement Une Fois",
  },
  {
    code: "INCLUDE_INVALID_ITEM",
    language: "Inclure Un Article Non Valide",
  },
  {
    code: "HAVE_BEEN_PAID_OR_NOT_EXIST",
    language: "Ont Été Payé Ou N'Existe Pas",
  },
  {
    code: "DURATION_TIME_30_MINUTES_AT_LEAST",
    language: "Durée Temps De 30 Minutes Au Moins",
  },
  {
    code: "END_TIME_MUST_BE_GATHER_THAN_START_TIME",
    language: "L'Heure De Fin Doit Être Complète À L'Heure De Début",
  },
  {
    code: "NUMBER_OF_STUDENT_MUST_BE_LESS_THAN_CAPACITY_OF_ROOM",
    language:
      "Le Nombre D'Étudiant Doit Être Inférieur À La Capacité De La Chambre",
  },
  {
    code: "BROCHURE_NOT_FOUND",
    language: "Brochure Non Trouvée",
  },
  {
    code: "FEEDBACK_NOT_FOUND",
    language: "Feedback Pas Trouvé",
  },
  {
    code: "PHONE_IS_NOT_ALLOWED_TO_BE_EMPTY",
    language: "Le Téléphone N'Est Pas Autorisé À Être Vide",
  },
  {
    code: "AVATARURL_IS_NOT_ALLOWED_TO_BE_EMPTY",
    language: "L'Url Avatar N'Est Pas Autorisée À Être Vide",
  },
  {
    code: "ENDTIME_CONTAINS_AN_INVALID_VALUE",
    language: "End Time Enthält Einen Ungültigen Wert",
  },
  {
    code: "VALUE_MUST_BE_LESS_THAN_OR_EQUAL_TO_100",
    language: "La valeur doit être inférieure ou égale à 100",
  },
];
