import { MainApi } from "./endpoint";
// import { mockData } from '../utils/tools'

export const createGroup = (payload) => MainApi.post("/admin/groups", payload);
export const updateGroup = (payload) => {
  const handlePayload = { ...payload };
  const id = handlePayload.id;
  delete handlePayload.id;

  return MainApi.put(`/admin/groups/${id}`, handlePayload);
};
export const getGroups = (payload) => {
  const handlePayload = { ...payload };
  const id = handlePayload.id;
  delete handlePayload.id;
  return MainApi.get(`/admin/groups/centers/${id}`, handlePayload);
};
export const getGroupDetail = (payload) =>
  MainApi.get(`/admin/groups/${payload.id}`);
export const deleteGroup = (payload) =>
  MainApi.delete(`/admin/groups/${payload.id}`);
export const getInternships = (payload) => {
  const handlePayload = { ...payload };
  const id = handlePayload.id;
  delete handlePayload.id;
  return MainApi.get(
    `/admin/groups/centers/${id}?type=INTERNSHIP`,
    handlePayload,
  );
};
export const createInternship = (payload) =>
  MainApi.post("/admin/groups", payload);
export const updateInternship = (payload) => {
  const handlePayload = { ...payload };
  const id = handlePayload.id;
  delete handlePayload.id;
  return MainApi.put(`/admin/groups/${id}`, handlePayload);
};
export const deleteInternship = (payload) =>
  MainApi.delete(`/admin/groups/${payload.id}`);
export const showHomepage = (payload) =>
  MainApi.put(`/admin/groups/${payload.id}/show-homepage`, payload);
export const getAllGroups = (payload) =>
  MainApi.get(`/admin/groups/centers/${payload.id}`);
export const moveStudentsNewGroup = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id_old_group;

  return MainApi.post(
    `/admin/groups/${payload.id_old_group}/move-students`,
    handlePayload,
  );
};
export const showInternshipList = ({ id }) =>
  MainApi.put(`/admin/groups/${id}/show-internship`);
