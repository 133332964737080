import { TYPES } from "../actions";

const INIT_STATE = {
  loaded: [],
  submitting: null,
  error: null,
  username: null,
  permissions: [],
  roleName: null,
  infoUser: {},
  token: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.LOGIN_REQUEST:
    case TYPES.LOGOUT_REQUEST:
    case TYPES.GET_INFO_FIRST_LOAD_REQUEST:
    case TYPES.CHANGE_PASS_REQUEST:
    case TYPES.UPDATE_PROFILE_REQUEST:
    case TYPES.HANDLE_SEND_MAIL_REQUEST:
    case TYPES.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        submitting: null,

        infoUser: action.data,
        roleName: action.data.role,
        token: action.data.token,
      };
    case TYPES.GET_INFO_FIRST_LOAD_SUCCESS:
      return {
        ...state,
        submitting: null,

        infoUser: action.data.infoUser,
        roleName: action.data.roleName,
        token: action.data.token,
      };
    case TYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        submitting: null,

        infoUser: {},
      };

    case TYPES.HANDLE_SEND_MAIL_SUCCESS:
      return {
        ...state,
        submitting: null,
      };

    case TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: null,
      };

    case TYPES.CHANGE_PASS_SUCCESS:
    case TYPES.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        submitting: null,
        infoUser: {
          ...state.infoUser,
          ...action.payload,
        },
      };
    case TYPES.CHANGE_PASS_FAILURE:
    case TYPES.LOGIN_FAILURE:
    case TYPES.LOGOUT_FAILURE:
    case TYPES.GET_INFO_FIRST_LOAD_FAILURE:
    case TYPES.UPDATE_PROFILE_FAILURE:
    case TYPES.HANDLE_SEND_MAIL_FAILURE:
    case TYPES.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
