import { all } from "redux-saga/effects";

import account from "./account";
import ui from "./ui";
import centers from "./centers";
import rooms from "./rooms";
import subjects from "./subjects";
import users from "./users";
import groups from "./groups";
import common from "./common";
import appointments from "./appointments";
import contents from "./contents";
import schedule from "./schedule";
import discounts from "./discounts";
import marketing from "./marketing";
import slide from "./slide";
import blog from "./blog";

export default function* sagas() {
  yield all([
    account(),
    ui(),
    centers(),
    rooms(),
    subjects(),
    users(),
    groups(),
    common(),
    appointments(),
    contents(),
    schedule(),
    discounts(),
    marketing(),
    slide(),
    blog(),
  ]);
}
