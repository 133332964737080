import { MainApi } from "./endpoint";

export const getCenters = () => MainApi.get("/admin/centers");
export const createCenter = (payload) =>
  MainApi.post("/admin/centers", payload);
export const updateCenter = (payload) => {
  const handlePayload = { ...payload };
  const id = handlePayload.id;
  delete handlePayload.id;
  delete handlePayload.createAt;
  return MainApi.put(`/admin/centers/${id}`, handlePayload);
};
export const deleteCenter = (payload) =>
  MainApi.delete(`/admin/centers/${payload.id}`);
export const getAllTimelines = (payload) => {
  const handleQuery = { ...payload };
  delete handleQuery.id;

  return MainApi.get(`/admin/timelines/center/${payload.id}`, handleQuery);
};
export const getTimelinesStatistics = (payload) =>
  MainApi.get(`/admin/timelines/statistics/center/${payload.id}`);
export const createHoliday = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.currentCenter;

  return MainApi.put(
    `/admin/centers/${payload.currentCenter}/holiday`,
    handlePayload,
  );
};

export const cancelHoliday = ({ currentCenter, type }) =>
  MainApi.put(`/admin/centers/${currentCenter}/holiday`, { type });
