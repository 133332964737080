import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  getAllSchedule,
  getGroupInfo,
  getCommentGroupId,
  getAgendaGroupId,
} from "../../api/schedule";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.GET_ALL_SCHEDULE, sagaRunning({ api: getAllSchedule })),
    takeLatest(TYPES.GET_GROUP_INFO, sagaRunning({ api: getGroupInfo })),
    takeLatest(
      TYPES.GET_COMMENT_GROUP_ID,
      sagaRunning({ api: getCommentGroupId }),
    ),
    takeLatest(
      TYPES.GET_AGENDA_GROUP_ID,
      sagaRunning({ api: getAgendaGroupId }),
    ),
  ]);
}
