import { MainApi } from "./endpoint";

export const getListCategoriesBlog = (payload) =>
  MainApi.get("/admin/categories", payload);
export const createCategoryBlog = (payload) =>
  MainApi.post("/admin/categories", payload);
export const deleteCategoryBlog = (payload) =>
  MainApi.delete(`/admin/categories/${payload.id}`);
export const updateCategoryBlog = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;

  return MainApi.put(`/admin/categories/${payload.id}`, handlePayload);
};

export const getListBlogs = (payload) => MainApi.get("/admin/blog", payload);
export const createBlog = (payload) => MainApi.post("/admin/blog", payload);
export const updateBlog = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;

  return MainApi.put(`/admin/blog/${payload.id}`, handlePayload);
};
export const deleteBlog = (payload) =>
  MainApi.delete(`/admin/blog/${payload.id}`);
