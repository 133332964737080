import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
// import { withNamespaces } from 'react-i18next'
import { object, string } from "yup";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Modal from "../../../components/modal";
import Field from "../../../components/field";
import Notification from "../../../components/notification";
import { TYPES, actions } from "../../../store/actions";
import Storage from "../../../utils/storage";

import "./style.scss";
import { forwardInnerRef } from "../../../utils/high-order-functions";
import ImageAvatar from "../../../media/image/profile-avatar.png";
import { getBase64 } from "../../../utils/tools";

const validationSchema = object().shape({
  firstName: string().required("Obligatoire"),
  lastName: string().required("Obligatoire"),
  email: string().required("Obligatoire"),
});

class UpdateProfile extends React.Component {
  state = {
    visible: false,
    urlAvatar: "",
    urlBase64: "",
  };

  open = () => {
    this.setState({
      visible: true,
    });
  };

  close = () => {
    this.setState({
      visible: false,
      urlAvatar: "",
      urlBase64: "",
    });
  };

  _onSubmit = (values) => {
    const { updateProfile } = this.props;
    const { urlAvatar } = this.state;
    const handlePayload = { ...values };
    if (urlAvatar) {
      handlePayload.avatarUrl = urlAvatar;
    }

    delete handlePayload.email;

    updateProfile(handlePayload, (action) => {
      if (action === TYPES.UPDATE_PROFILE_SUCCESS) {
        const info = Storage.get("INFO_USER");
        const handleStore = {
          ...info,
          ...handlePayload,
        };

        Storage.set("INFO_USER", handleStore);
        this.close();
        return Notification.success("Mise à jour du profil de réussite");
      }
    });
  };

  _onSelectImage = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const { uploadFile } = this.props;
      uploadFile({ files: [file] }, async (action, data) => {
        if (action === TYPES.UPLOAD_FILE_SUCCESS) {
          const { url } = data[0];
          const urlBase64 = await getBase64(file);
          this.setState({ urlAvatar: url, urlBase64 });
        }
      });
    }
  };

  _renderForm = ({ handleSubmit, ...form }) => {
    const {
      accountStore: { submitting, infoUser },
    } = this.props;
    const { urlBase64 } = this.state;

    return (
      <Form className="form-profile">
        <div className="content">
          <div className="field-profile">
            <div className="avatar">
              <div className="label">Avatar</div>
              <div className="box-avatar">
                <img
                  onClick={() => this.changeAvatar.click()}
                  src={
                    urlBase64
                      ? urlBase64
                      : infoUser.avatar
                        ? infoUser.avatar
                        : ImageAvatar
                  }
                  alt=""
                />
                <input
                  type="file"
                  accept="images/*"
                  style={{ display: "none" }}
                  ref={(ref) => {
                    this.changeAvatar = ref;
                  }}
                  onChange={(e) => this._onSelectImage(e)}
                />
              </div>
            </div>
            <Field
              className="field"
              form={form}
              name="firstName"
              component={Input}
              label="Prénom"
            />
            <Field
              className="field"
              form={form}
              name="lastName"
              component={Input}
              label="Nom de famille"
            />
            <Field
              className="field"
              form={form}
              name="email"
              disabled
              component={Input}
              label="Emails"
            />
          </div>
          <div className="profile">
            <Button type="basic" className="btn-close" onClick={this.close}>
              Fermer
            </Button>
            <Button
              htmlType="submit"
              className="btn-profile"
              type="primary"
              loading={!!submitting}
              onClick={handleSubmit}
            >
              Mise à jour
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  render() {
    const {
      accountStore: { infoUser },
    } = this.props;
    const { visible } = this.state;
    const initialValues = {
      firstName: infoUser.firstName,
      lastName: infoUser.lastName,
      email: infoUser.email,
    };

    return (
      <Modal
        visible={visible}
        onCancel={this.close}
        destroyOnClose
        title="Profil"
        className="modal-profile"
      >
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this._onSubmit}
          component={this._renderForm}
        />
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    accountStore: state.account,
  }),
  {
    updateProfile: actions.updateProfile,
    uploadFile: actions.uploadFile,
  },
)(forwardInnerRef(UpdateProfile));
