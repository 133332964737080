import React, { Component } from "react";
import { Select } from "antd";
import classNames from "classnames";
import "antd/es/select/style/css";

import "./style.scss";

const { Option } = Select;

export default class extends Component {
  _onChange = (value) => {
    const { field, onChange, name } = this.props;

    if (onChange) {
      onChange({ target: { value, name: field?.name || name } });
    }
    if (field) {
      field.onChange({ target: { value, name: field.name } });
    }
  };

  _renderOption = (option, index) => {
    if (typeof option === "string" || typeof option === "number") {
      return (
        <Option key={index} value={option}>
          {option}
        </Option>
      );
    }

    const { optionBinding, renderOption } = this.props;

    let value;
    let name;
    if (!optionBinding) {
      value = option.value;
      name = option.name;
    } else {
      value = option[optionBinding.value];
      name = option[optionBinding.name];
    }

    return (
      <Option key={index} value={value} name={name}>
        {renderOption ? renderOption({ value, name }) : name}
      </Option>
    );
  };

  render() {
    const {
      field,
      options,
      onChange,
      optionBinding,
      value,
      renderOption,
      className,
      ...props
    } = this.props;

    return (
      <Select
        className={classNames(
          className,
          "select-custom-default ant-select-open",
        )}
        {...field}
        {...props}
        onChange={this._onChange}
        value={field?.value || value}
      >
        {options.map(this._renderOption)}
      </Select>
    );
  }
}
