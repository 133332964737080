import { MainApi } from "./endpoint";
// import { mockData } from '../utils/tools'

export const getTeachers = (payload) => MainApi.get("/admin/teachers", payload);
export const getStudents = (payload) =>
  MainApi.get("/admin/customers/students", payload);
export const getStudentsByGroup = (payload) =>
  MainApi.get(`/admin/groups/${payload.id}/students`, payload);
export const getParents = (payload) =>
  MainApi.get("/admin/customers/student-management", payload);
export const inviteInterview = (payload) =>
  MainApi.post("/admin/teachers/invite-interview", payload);
export const getHistoryTransaction = (payload) =>
  MainApi.get(`/admin/customers/${payload.id}/transaction-history`, payload);
export const updateTeacher = (payload) => {
  const handlepayload = { ...payload };
  delete handlepayload.center;

  return MainApi.put("/admin/teachers", handlepayload);
};
export const createTeacher = (payload) =>
  MainApi.post("/admin/teachers", payload);

export const changeStatusTeacher = (payload) => {
  const handlepayload = {
    id: payload.id,
    type: payload.type,
  };

  return MainApi.put("/admin/teachers/change-status", handlepayload);
};
export const deleteTeacher = (payload) =>
  MainApi.delete(`/admin/teachers/${payload.id}`);
export const getManagers = (payload) => MainApi.get("/admin/us", payload);
export const updateManager = (payload) => {
  const handlepayload = { ...payload };
  delete handlepayload.id;

  return MainApi.put(
    `/admin/us/owner-update/admin/${payload.id}`,
    handlepayload,
  );
};
export const deleteManager = (payload) =>
  MainApi.delete(`/admin/us/${payload.id}`);
export const createManager = (payload) => MainApi.post("/admin/us/", payload);
export const getTransactionTeacher = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;

  return MainApi.get(`/admin/teachers/${payload.id}/invoices`, handlePayload);
};
export const updateParent = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;
  return MainApi.put(`/admin/customers/parents/${payload.id}`, handlePayload);
};

export const updateStudent = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;
  return MainApi.put(`/admin/customers/students/${payload.id}`, handlePayload);
};
export const updateStatusCustomer = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;
  return MainApi.put(`/admin/customers/${payload.id}`, handlePayload);
};
export const deleteCustomer = (payload) =>
  MainApi.delete(`/admin/customers/${payload.id}`);

export const updateTransactionTeacher = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;

  return MainApi.put(`/admin/us/invoice/${payload.id}/update`, handlePayload);
};
