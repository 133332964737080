import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,

  rooms: [],
  count: null,
  currentRoom: null,
  currentCenterRoom: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_ROOMS_REQUEST:
    case TYPES.CREATE_ROOM_REQUEST:
    case TYPES.UPDATE_ROOM_REQUEST:
    case TYPES.DELETE_ROOM_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.CREATE_ROOM_SUCCESS:
      return {
        ...state,
        submitting: null,

        rooms: [action.data, ...state.rooms],
        currentRoom: state.rooms.length ? state.currentRoom : action.data,
      };
    case TYPES.UPDATE_ROOM_SUCCESS:
      const newRoom = state.rooms
        .map((r) => (r.id === action.payload.id ? action.payload : r))
        .filter((r) => r.centerId === state.currentCenterRoom);
      return {
        ...state,
        submitting: null,

        rooms: newRoom,
        currentRoom:
          action.payload.centerId === state.currentCenterRoom
            ? action.payload
            : newRoom.length
              ? newRoom[0]
              : null,
      };
    case TYPES.DELETE_ROOM_SUCCESS:
      const newRooms = state.rooms.filter((r) => r.id !== action.payload.id);
      return {
        ...state,
        submitting: null,

        rooms: newRooms,
        currentRoom: newRooms.length ? newRooms[0] : null,
      };
    case TYPES.GET_ROOMS_SUCCESS:
      return {
        ...state,
        submitting: null,
        rooms: action.data.rows,
        count: action.data.count,
        currentRoom: action.data.rows.length ? action.data.rows[0] : null,
        currentCenterRoom: action.data.rows.length
          ? action.data.rows[0].centerId
          : null,
      };
    case TYPES.SET_CURRENT_ROOM:
      return {
        ...state,
        submitting: null,

        currentRoom: action.data,
      };
    case TYPES.CREATE_ROOM_FAILURE:
    case TYPES.UPDATE_ROOM_FAILURE:
    case TYPES.DELETE_ROOM_FAILURE:
    case TYPES.GET_ROOMS_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
