import { push } from "connected-react-router";
import { put /* , call */ } from "redux-saga/effects";
import LocalStorage from "./storage";

export default function sagaRun({ api }) {
  return function* ({ type, data, callback }) {
    const successType = `${type}_SUCCESS`;
    const failureType = `${type}_FAILURE`;

    try {
      yield put({ type: `${type}_REQUEST`, payload: data });
      const res = yield api(data);

      if (
        res.status === 204 ||
        res.status === 202 ||
        res.status === 201 ||
        res.status === 200
      ) {
        yield put({ type: successType, data: res.data, payload: data });
        if (callback) {
          callback(successType, res.data);
        }
      } else {
        throw res.errors;
      }
    } catch (res) {
      // HANDEL ERR - else
      if (res.status === 401) {
        LocalStorage.clear();
        return yield put(push("/login"));
      }

      yield put({ type: failureType, data: res.data, payload: data });
      if (callback) {
        callback(failureType, res.data);
      }
    }
  };
}
