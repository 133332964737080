import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,

  limit: 10,
  appointmentsSlotTime: {
    rows: [],
    count: 0,
    page: 1,
  },
  appointmentsSlotDate: {
    rows: [],
    count: 0,
    page: 1,
  },
  currentCenterRoom: null,
  listBooking: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_APPOINTMENTS_REQUEST:
    case TYPES.CREATE_APPOINTMENT_REQUEST:
    case TYPES.UPDATE_APPOINTMENT_REQUEST:
    case TYPES.DELETE_APPOINTMENT_REQUEST:
    case TYPES.GET_BOOKING_REQUEST:
    case TYPES.DELETE_BOOKING_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        submitting: null,

        appointmentsSlotTime: !action.payload?.date
          ? {
              rows: [action.data, ...state.appointmentsSlotTime.rows],
              count: state.appointmentsSlotTime.count + 1,
            }
          : state.appointmentsSlotTime,
        appointmentsSlotDate: action.payload?.date
          ? {
              rows: [action.data, ...state.appointmentsSlotDate.rows],
              count: state.appointmentsSlotDate.count + 1,
            }
          : state.appointmentsSlotDate,
      };
    case TYPES.UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        submitting: null,

        appointmentsSlotTime: {
          ...state.appointmentsSlotTime,
          rows: state.appointmentsSlotTime.rows.map((a) =>
            a.id === action.data.id
              ? {
                  ...action.data,
                  selectedDays: JSON.parse(action.data.selectedDays),
                }
              : a,
          ),
        },
      };

    case TYPES.DELETE_APPOINTMENT_SUCCESS:
      const newSlotTime = state.appointmentsSlotTime.rows.filter(
        (r) => r.id !== action.data.id,
      );
      const newSlotDate = state.appointmentsSlotDate.rows.filter(
        (r) => r.id !== action.data.id,
      );

      return {
        ...state,
        submitting: null,

        appointmentsSlotTime: {
          rows: newSlotTime,
          count:
            state.appointmentsSlotTime.rows.length === newSlotTime.length
              ? state.appointmentsSlotTime.count
              : state.appointmentsSlotTime.count - 1,
        },
        appointmentsSlotDate: {
          rows: newSlotDate,
          count:
            state.appointmentsSlotDate.rows.length === newSlotDate.length
              ? state.appointmentsSlotDate.count
              : state.appointmentsSlotDate.count - 1,
        },
      };

    case TYPES.DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        submitting: null,
        listBooking: state.listBooking.filter((r) => r.id !== action.data.id),
      };

    case TYPES.GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        submitting: null,
        appointmentsSlotTime:
          action.payload.type === "SLOT_TIME"
            ? {
                rows: action.data.rows,
                count: action.data.count,
              }
            : state.appointmentsSlotTime,
        appointmentsSlotDate:
          action.payload.type === "SLOT_DATE"
            ? {
                rows: action.data.rows,
                count: action.data.count,
              }
            : state.appointmentsSlotDate,
      };

    case TYPES.GET_BOOKING_SUCCESS:
      return {
        ...state,
        submitting: null,
        count: action.data?.count,
        listBooking: action.data.rows,
      };
    case TYPES.CREATE_APPOINTMENT_FAILURE:
    case TYPES.UPDATE_APPOINTMENT_FAILURE:
    case TYPES.DELETE_APPOINTMENT_FAILURE:
    case TYPES.GET_APPOINTMENTS_FAILURE:
    case TYPES.GET_BOOKING_FAILURE:
    case TYPES.DELETE_BOOKING_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
