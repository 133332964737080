import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  createContent,
  getContents,
  updateContent,
  deleteContent,
} from "../../api/contents";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.CREATE_CONTENT, sagaRunning({ api: createContent })),
    takeLatest(TYPES.UPDATE_CONTENT, sagaRunning({ api: updateContent })),
    takeLatest(TYPES.DELETE_CONTENT, sagaRunning({ api: deleteContent })),
    takeLatest(TYPES.GET_CONTENTS, sagaRunning({ api: getContents })),
  ]);
}
