import { MainApi } from "./endpoint";
// import { mockData } from '../utils/tools'

export const createDiscounts = (payload) =>
  MainApi.post("/admin/discounts", payload);
export const updateDiscounts = (payload) => {
  const handlePayload = { ...payload };
  delete handlePayload.id;
  return MainApi.put(`/admin/discounts/${payload.id}`, handlePayload);
};
export const deleteDiscounts = (payload) =>
  MainApi.delete(`/admin/discounts/${payload.id}`);
export const getDiscounts = (payload) =>
  MainApi.get("/admin/discounts", payload);
