import { MainApi } from "./endpoint";

export const createSlide = (payload) => MainApi.post("/admin/slides", payload);

export const getAllSlide = (payload) => MainApi.get("/admin/slides", payload);
export const getDetailSlide = (payload) =>
  MainApi.get(`/admin/slides/${payload.id}`);

export const deleteSlide = (payload) =>
  MainApi.delete(`/admin/slides/${payload.id}`);

export const updateSlide = (payload) => {
  const handlePayload = { ...payload };
  const id = handlePayload.id;
  delete handlePayload.id;

  return MainApi.put(`/admin/slides/${id}`, handlePayload);
};

export const setOrderSlice = ({ order, id }) =>
  MainApi.put(`/admin/slides/${id}`, { order });
