import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,
  count: null,
  slides: [],
  slideDetail: null,
  isRefresh: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_ALL_SLIDE_REQUEST:
    case TYPES.CREATE_SLIDE_REQUEST:
    case TYPES.UPDATE_SLIDE_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };

    case TYPES.GET_ALL_SLIDE_SUCCESS:
      let modData = [...(action.data.rows || [])];
      modData = modData.map((dt, index) =>
        dt.order !== null
          ? {
              ...dt,
              index,
            }
          : {
              ...dt,
              index,
              order: index + 1,
            },
      );

      return {
        ...state,
        submitting: null,
        slides: modData,
      };
    case TYPES.SET_SLICE:
      return {
        ...state,
        submitting: null,
        slides: action.data,
      };

    case TYPES.CREATE_SLIDE_SUCCESS:
      return {
        ...state,
        submitting: null,
        slides: [...state.slides, action.data],
      };

    case TYPES.UPDATE_SLIDE_SUCCESS:
      return {
        ...state,
        submitting: null,
        slides: state.slides.map((e) =>
          e.id === action.data.id ? action.data : e,
        ),
        slideDetail: action.data,
      };

    case TYPES.DELETE_SLIDE_SUCCESS:
      return {
        ...state,
        submitting: null,
        slides: state.slides.filter((e) => e.id !== action.payload.id),
      };

    case TYPES.GET_ALL_SLIDE_FAILURE:
    case TYPES.CREATE_SLIDE_FAILURE:
    case TYPES.UPDATE_SLIDE_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };

    default:
      return state;
  }
};
