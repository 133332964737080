import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import { createRoom, getRooms, updateRoom, deleteRoom } from "../../api/rooms";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.CREATE_ROOM, sagaRunning({ api: createRoom })),
    takeLatest(TYPES.UPDATE_ROOM, sagaRunning({ api: updateRoom })),
    takeLatest(TYPES.DELETE_ROOM, sagaRunning({ api: deleteRoom })),
    takeLatest(TYPES.GET_ROOMS, sagaRunning({ api: getRooms })),
  ]);
}
