import { all, takeLatest } from "redux-saga/effects";
import sagaRunning from "utils/specialSaga";
import { TYPES } from "store/actions";
import {
  getListContact,
  getDetailContact,
  editListContact,
  deleteContact,
  createListContact,
  //space
  getListLists,
  getDetailList,
  editInnerLists,
  deleteInTheList,
  createListInnerList,
  getContactInAList,
  getListFolders,
  //space
  deleteFolder,
  createFolder,
  EditFolder,
  //space
  addContactToList,
  deleteContactToList,
  //Get list email
  getListEmail,
  editEmail,
  deleteEmail,
  createListEmail,
  createListEmailNow,
  getDetailEmail,
  //Get list sender
  getListSender,
  //Get list template
  getListTemplate,
  //Get list recipient
  getListRecipient,
  getMarketingListByFolder,
  importContact,
  exportContact,
} from "api/marketing";

export default function* watcher() {
  yield all([
    // MEANS - MARKETING EMAIL
    takeLatest(
      TYPES.GET_MARKETING_LIST_BY_FOLDER,
      sagaRunning({ api: getMarketingListByFolder }),
    ),
    takeLatest(
      TYPES.GET_MARKETING_EMAIL_SENDERS,
      sagaRunning({ api: getListSender }),
    ),
    takeLatest(
      TYPES.GET_MARKETING_EMAIL_TEMPLATES,
      sagaRunning({ api: getListTemplate }),
    ),
    takeLatest(
      TYPES.GET_MARKETING_EMAIL_RECIPIENTS,
      sagaRunning({ api: getListRecipient }),
    ),
    takeLatest(TYPES.GET_MARKETING_EMAILS, sagaRunning({ api: getListEmail })),
    takeLatest(
      TYPES.GET_MARKETING_EMAIL_DETAIL,
      sagaRunning({ api: getDetailEmail }),
    ),
    takeLatest(
      TYPES.CREATE_MARKETING_EMAIL,
      sagaRunning({ api: createListEmail }),
    ),
    takeLatest(
      TYPES.CREATE_MARKETING_EMAIL_NOW,
      sagaRunning({ api: createListEmailNow }),
    ),
    takeLatest(TYPES.EDIT_MARKETING_EMAIL, sagaRunning({ api: editEmail })),
    takeLatest(TYPES.DELETE_MARKETING_EMAIL, sagaRunning({ api: deleteEmail })),

    // MEANS - MARKETING CONTACT
    takeLatest(
      TYPES.GET_MARKETING_CONTACTS,
      sagaRunning({ api: getListContact }),
    ),
    takeLatest(
      TYPES.GET_MARKETING_CONTACT_DETAIL,
      sagaRunning({ api: getDetailContact }),
    ),
    takeLatest(
      TYPES.EDIT_MARKETING_CONTACT,
      sagaRunning({ api: editListContact }),
    ),
    takeLatest(
      TYPES.DELETE_MARKETING_CONTACT,
      sagaRunning({ api: deleteContact }),
    ),
    takeLatest(
      TYPES.CREATE_MARKETING_CONTACT,
      sagaRunning({ api: createListContact }),
    ),

    // MEANS - MARKETING LIST
    takeLatest(TYPES.GET_MARKETING_LISTS, sagaRunning({ api: getListLists })),
    takeLatest(
      TYPES.GET_MARKETING_LIST_DETAIL,
      sagaRunning({ api: getDetailList }),
    ),
    takeLatest(TYPES.EDIT_MARKETING_LIST, sagaRunning({ api: editInnerLists })),
    takeLatest(
      TYPES.DELETE_MARKETING_LIST,
      sagaRunning({ api: deleteInTheList }),
    ),
    // MEANS:  CONTACT IN LIST
    takeLatest(
      TYPES.CREATE_MARKETING_LIST,
      sagaRunning({ api: createListInnerList }),
    ),
    takeLatest(
      TYPES.GET_CONTACT_IN_LIST,
      sagaRunning({ api: getContactInAList }),
    ),
    // MEANS: Contact in folders
    takeLatest(
      TYPES.GET_MARKETING_FOLDERS,
      sagaRunning({ api: getListFolders }),
    ),
    takeLatest(
      TYPES.DELETE_MARKETING_FOLDER,
      sagaRunning({ api: deleteFolder }),
    ),
    takeLatest(
      TYPES.CREATE_MARKETING_FOLDER,
      sagaRunning({ api: createFolder }),
    ),
    takeLatest(TYPES.EDIT_MARKETING_FOLDER, sagaRunning({ api: EditFolder })),

    //note : get all folder -> handle list select

    takeLatest(
      TYPES.GET_MARKETING_FOLDERS_SELECT,
      sagaRunning({ api: getListFolders }),
    ),

    //space
    takeLatest(
      TYPES.GET_MARKETING_CONTACTS_SELECT,
      sagaRunning({ api: getListContact }),
    ),
    //note: Add existing contacts to a list
    takeLatest(
      TYPES.ADD_CONTACTS_TO_LIST,
      sagaRunning({ api: addContactToList }),
    ),
    takeLatest(
      TYPES.DELETE_CONTACTS_TO_LIST,
      sagaRunning({ api: deleteContactToList }),
    ),
    takeLatest(TYPES.IMPORT_CONTACT, sagaRunning({ api: importContact })),
    takeLatest(TYPES.EXPORT_CONTACT, sagaRunning({ api: exportContact })),
  ]);
}
