import { TYPES } from "../actions";

const INIT_STATE = {
  submitting: null,
  error: null,

  teachers: [],
  teachersActive: [],
  students: [],
  managers: [],
  parents: null,
  count: null,
  countTransaction: null,
  historyTransaction: [],

  transactionTeacher: [],
  countTransactionTeacher: 0,

  studentsGroup: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_TEACHERS_REQUEST:
    case TYPES.GET_STUDENTS_REQUEST:
    case TYPES.GET_PARENTS_REQUEST:
    case TYPES.SEND_MAIL_INTERVIEW_REQUEST:
    case TYPES.CREATE_TEACHER_REQUEST:
    case TYPES.UPDATE_TEACHER_REQUEST:
    case TYPES.DELETE_TEACHER_REQUEST:
    case TYPES.CHANGE_STATUS_TEACHER_REQUEST:
    case TYPES.INVITE_INTERVIEW_REQUEST:
    case TYPES.GET_MANAGERS_REQUEST:
    case TYPES.DELETE_MANAGER_REQUEST:
    case TYPES.UPDATE_MANAGER_REQUEST:
    case TYPES.CREATE_MANAGER_REQUEST:
    case TYPES.GET_HISTORY_TRANSACTION_REQUEST:
    case TYPES.GET_TRANSACTION_TEACHER_REQUEST:
    case TYPES.UPDATE_STUDENT_REQUEST:
    case TYPES.UPDATE_PARENT_REQUEST:
    case TYPES.GET_STUDENTS_BY_GROUP_REQUEST:
    case TYPES.UPDATE_TRANSACTION_TEACHER_REQUEST:
      return {
        ...state,
        submitting: action.type,
      };
    case TYPES.GET_TEACHERS_SUCCESS:
      return {
        ...state,
        submitting: null,

        teachers: action.data?.rows,
        count: action.data?.count,
        teachersActive: action.data?.rows.filter((te) => te.active),
      };

    case TYPES.GET_STUDENTS_BY_GROUP_SUCCESS:
      return {
        ...state,
        submitting: null,
        studentsGroup: action.data,
      };

    case TYPES.GET_HISTORY_TRANSACTION_SUCCESS:
      return {
        ...state,
        submitting: null,

        historyTransaction: action.data?.rows,
        countTransaction: action.data?.count,
      };

    case TYPES.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        submitting: null,

        students: action.data?.rows,
        count: action.data?.count,
      };
    case TYPES.GET_PARENTS_SUCCESS:
      return {
        ...state,
        submitting: null,
        parents: action.data?.rows,
        count: action.data?.count,
      };
    case TYPES.GET_MANAGERS_SUCCESS:
      return {
        ...state,
        submitting: null,
        managers: action.data?.rows,
        count: action.data?.count,
      };
    case TYPES.CREATE_MANAGER_SUCCESS:
      return {
        ...state,
        submitting: null,

        managers: [action.data, ...state.managers],
      };

    case TYPES.CREATE_TEACHER_SUCCESS:
      return {
        ...state,
        submitting: null,
        teachers: [action.data, ...state.teachers],
      };
    case TYPES.UPDATE_MANAGER_SUCCESS: {
      return {
        ...state,
        submitting: null,
        managers: state.managers.map((m) =>
          m.id === action.payload.id ? action.payload : m,
        ),
      };
    }
    case TYPES.DELETE_MANAGER_SUCCESS: {
      return {
        ...state,
        submitting: null,
        managers: state.managers.filter((m) => m.id !== action.payload.id),
      };
    }
    case TYPES.CHANGE_STATUS_TEACHER_SUCCESS:
    case TYPES.UPDATE_TEACHER_SUCCESS:
      return {
        ...state,
        submitting: null,

        teachers: state.teachers.map((t) =>
          t.id === action.payload.id ? action.payload : t,
        ),
      };
    case TYPES.DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        submitting: null,

        teachers: state.teachers.filter((t) => t.id !== action.payload.id),
      };
    case TYPES.INVITE_INTERVIEW_SUCCESS:
      return {
        ...state,
        submitting: null,
      };
    case TYPES.GET_TRANSACTION_TEACHER_SUCCESS:
      return {
        ...state,
        submitting: null,
        transactionTeacher: action.data?.rows,
        countTransactionTeacher: action.data?.count,
      };
    case TYPES.UPDATE_STUDENT_SUCCESS:
    case TYPES.UPDATE_PARENT_SUCCESS:
      return {
        ...state,
        submitting: null,
      };
    case TYPES.UPDATE_TRANSACTION_TEACHER_SUCCESS:
      return {
        ...state,
        submitting: null,
        transactionTeacher: state.transactionTeacher.map((tran) =>
          tran.id === action.payload.id
            ? {
                ...tran,
                ...action.payload,
              }
            : tran,
        ),
      };
    case TYPES.UPDATE_TRANSACTION_TEACHER_FAILURE:
    case TYPES.UPDATE_STUDENT_FAILURE:
    case TYPES.UPDATE_PARENT_FAILURE:
    case TYPES.CREATE_MANAGER_FAILURE:
    case TYPES.GET_MANAGERS_FAILURE:
    case TYPES.DELETE_MANAGER_FAILURE:
    case TYPES.CREATE_TEACHER_FAILURE:
    case TYPES.UPDATE_MANAGER_FAILURE:
    case TYPES.UPDATE_TEACHER_FAILURE:
    case TYPES.DELETE_TEACHER_FAILURE:
    case TYPES.CHANGE_STATUS_TEACHER_FAILURE:
    case TYPES.INVITE_INTERVIEW_FAILURE:
    case TYPES.GET_TEACHERS_FAILURE:
    case TYPES.GET_STUDENTS_FAILURE:
    case TYPES.GET_PARENTS_FAILURE:
    case TYPES.SEND_MAIL_INTERVIEW_FAILURE:
    case TYPES.GET_HISTORY_TRANSACTION_FAILURE:
    case TYPES.GET_TRANSACTION_TEACHER_FAILURE:
    case TYPES.GET_STUDENTS_BY_GROUP_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error,
      };
    default:
      return state;
  }
};
