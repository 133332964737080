import Axios from "axios";
import Storage from "../utils/storage";
const OTHER_KEY = process.env.REACT_APP_MARKETING_API_KEY;

class Request {
  static async create({ endpoint, method, data, headers }) {
    const config = {
      baseURL: endpoint,
      method: method,
      headers: {
        "Content-Type": "application/json",
        "api-key": OTHER_KEY,
        Authorization: `Bearer ${Storage.get("ACCESS_TOKEN")}`,
      },
    };
    if (method === "get") {
      config.params = data;
    } else {
      config.data = data;
    }

    try {
      const res = await Axios(config);
      return res;
    } catch (err) {
      return {
        success: false,
        message: "Error system",
        errors: err,
      };
    }
  }
}

export default Request;
