import React from "react";
import classNames from "classnames";
import { Field } from "formik";

import "./style.scss";

export default ({
  component: Component,
  translate,
  form,
  name,
  label,
  inline,
  style,
  ...props
}) => (
  // props = lodash.omit(props, [
  //   'activeLanguage',
  //   'addTranslation',
  //   'addTranslationForLanguage',
  //   'defaultLanguage',
  //   'ignoreTranslateChildren',
  //   'initialize',
  //   'languages',
  //   'setActiveLanguage',
  //   'renderToStaticMarkup'
  // ])

  <div style={style} className={classNames("box field", { inline })}>
    {label && <p className="label">{label}</p>}
    <div className="field-content">
      <Field {...props} name={name} component={Component} />
      <p className="error-message">
        {form && form.errors[name] && form.errors[name]}
      </p>
    </div>
  </div>
);
