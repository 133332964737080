import React from "react";

export const forwardInnerRef = (Component) => {
  class ForwardedComponent extends React.Component {
    componentDidMount() {
      const { innerRef } = this.props;
      if (innerRef) {
        innerRef(this._component);
      }
    }

    render() {
      return (
        <Component
          {...this.props}
          ref={(ref) => {
            this._component = ref;
          }}
        />
      );
    }
  }

  return ForwardedComponent;
};

export const renderManyDiv = (number, onClick) => {
  let renderDiv = document.createElement("div");
  for (let i = 0; i <= number; i++) {
    const el = document.createElement("p");
    el.className = "time-frame";
    el.onclick = onClick;
    renderDiv.appendChild(el);
  }

  return renderDiv.outerHTML.slice(5, renderDiv.outerHTML.length - 6);
};
