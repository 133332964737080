import { OtherApi } from "./endpoint";

export const getListEmail = (offset) => OtherApi.get("/emailCampaigns", offset);

export const getListSender = (offset) => OtherApi.get("/senders", offset);

export const getListTemplate = (payload) =>
  OtherApi.get("/smtp/templates", payload);

export const getListRecipient = (offset) =>
  OtherApi.get("/contacts/lists", offset);

export const getDetailEmail = (email) =>
  OtherApi.get(`/emailCampaigns/${email}`);

export const createListEmail = (payload) => {
  const strSender = payload.sender;
  let id = strSender.substring(strSender.length - 1, strSender.length);
  let sname = strSender.substring(0, strSender.length - 1);

  const { name, subject, recipients, scheduledAt, templateId } = payload;
  const newEmail = {
    name,
    subject,
    sender: {
      name: `${sname}`,
      id: Number(id),
    },
    recipients: {
      listIds: recipients,
    },
    scheduledAt,
    // recipients,
    templateId,
  };
  return OtherApi.post("/emailCampaigns", newEmail);
};

export const createListEmailNow = (payload) => {
  const id = payload.id;
  return OtherApi.post(`/emailCampaigns/${id}/sendNow`);
};

export const editEmail = (payload) => {
  const id = payload.id;
  const strSender = payload.newEmail.sender;
  let idSender = strSender.substring(strSender.length - 1, strSender.length);
  let nameSender = strSender.substring(0, strSender.length - 1);

  const { name, subject, recipients, scheduledAt } = payload.newEmail;
  const data = {
    name,
    subject,
    sender: {
      name: `${nameSender}`,
      id: Number(idSender),
    },
    scheduledAt,
    recipients: {
      listIds: recipients,
    },
  };
  return OtherApi.put(`/emailCampaigns/${id}`, data);
};

export const deleteEmail = (id) => OtherApi.delete(`/emailCampaigns/${id}`);

export const getListContact = (offset) => OtherApi.get("/contacts", offset);

export const getDetailContact = (email) => OtherApi.get(`/contacts/${email}`);

export const editListContact = (data) => {
  const { email, newContact } = data;
  const newData = {
    attributes: newContact,
  };
  return OtherApi.put(`/contacts/${email}`, newData);
};

export const createListContact = (payload) => {
  const { email, NOM } = payload;
  const newContact = {
    email,
    attributes: {
      NOM,
    },
  };
  return OtherApi.post("/contacts", newContact);
};

export const deleteContact = (email) => OtherApi.delete(`/contacts/${email}`);

//todo: marketing list
export const getListLists = (offset) => OtherApi.get("/contacts/lists", offset);

export const editInnerLists = (payload) => {
  const { currentId, values: newName } = payload;
  return OtherApi.put(`/contacts/lists/${currentId}`, newName);
};

export const createListInnerList = (newList) =>
  OtherApi.post("/contacts/lists", newList);

export const deleteInTheList = (currentId) =>
  OtherApi.delete(`/contacts/lists/${currentId}`);

export const getContactInAList = (payload) => {
  const { listId, query } = payload;
  return OtherApi.get(`/contacts/lists/${listId}/contacts`, query);
};

// todo: details marketing list
export const getDetailList = (listId) =>
  OtherApi.get(`/contacts/lists/${listId}`);

//note: Add existing contacts to a list
export const addContactToList = (payload) => {
  const { listId, values } = payload;
  return OtherApi.post(`/contacts/lists/${listId}/contacts/add`, values);
};

export const deleteContactToList = (payload) => {
  const { listId, values } = payload;
  return OtherApi.post(`/contacts/lists/${listId}/contacts/remove`, values);
};

// todo: marketing folders

export const getListFolders = (offset) =>
  OtherApi.get("/contacts/folders", offset);
export const createFolder = (name) => OtherApi.post("/contacts/folders", name);
export const EditFolder = (payload) => {
  const { currentId, values: newName } = payload;
  return OtherApi.put(`/contacts/folders/${currentId}`, newName);
};

export const deleteFolder = (currentId) =>
  OtherApi.delete(`/contacts/folders/${currentId}`);

export const getMarketingListByFolder = (payload) => {
  const handleQuery = { ...payload };
  delete handleQuery.folderId;

  return OtherApi.get(
    `/contacts/folders/${payload.folderId}/lists`,
    handleQuery,
  );
};

export const importContact = (payload) =>
  OtherApi.post("/contacts/import", payload);
export const exportContact = (payload) =>
  OtherApi.post("/contacts/export", payload);
