import { all, takeLatest, put } from "redux-saga/effects";
import sagaRunning from "../../utils/sagaRunning";
import { TYPES } from "../actions";
import {
  login,
  logout,
  changePass,
  updateProfile,
  handleSendMail,
  resetPassword,
  getCustomerInfo,
} from "../../api/account";
import LocalStorage from "../../utils/storage";

export default function* watcher() {
  yield all([
    takeLatest(TYPES.LOGIN, sagaRunning({ api: login })),
    takeLatest(TYPES.LOGOUT, sagaRunning({ api: logout })),
    takeLatest(TYPES.GET_INFO_FIRST_LOAD, getInfoFirstLoad),
    takeLatest(TYPES.CHANGE_PASS, sagaRunning({ api: changePass })),
    takeLatest(TYPES.UPDATE_PROFILE, sagaRunning({ api: updateProfile })),
    takeLatest(TYPES.HANDLE_SEND_MAIL, sagaRunning({ api: handleSendMail })),
    takeLatest(TYPES.RESET_PASSWORD, sagaRunning({ api: resetPassword })),
    takeLatest(TYPES.GET_CUSTOMER_INFO, sagaRunning({ api: getCustomerInfo })),
  ]);
}

function* getInfoFirstLoad({ callback }) {
  yield put({ type: TYPES.GET_INFO_FIRST_LOAD_REQUEST });
  const token = LocalStorage.get("ACCESS_TOKEN");
  const infoUser = LocalStorage.get("INFO_USER");
  const roleName = LocalStorage.get("ROLE");
  const currentCenter = LocalStorage.get("CURRENT_CENTER");

  if (infoUser && roleName) {
    yield put({
      type: TYPES.GET_INFO_FIRST_LOAD_SUCCESS,
      data: { infoUser, roleName, currentCenter, token },
    });
  } else {
    yield put({ type: TYPES.GET_INFO_FIRST_LOAD_FAILURE });
  }

  if (callback) {
    callback(TYPES.GET_INFO_FIRST_LOAD_SUCCESS, {
      infoUser,
      roleName,
      currentCenter,
      token,
    });
  }
}
