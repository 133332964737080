import React from "react";
import { Popover } from "antd";
import classNames from "classnames";
import { string, object } from "prop-types";

import "antd/es/popover/style/css";
import "./style.scss";

const PopoverComponent = ({
  placement,
  title,
  content,
  trigger,
  className,
  ...props
}) => (
  <Popover
    placement={placement || "bottom"}
    title={title || ""}
    content={content}
    trigger={trigger || "click"}
    {...props}
    className={classNames("popover-custom-default", className)}
  />
);

PopoverComponent.propTypes = {
  placement: string,
  title: string,
  content: object,
  trigger: string,
};

export default PopoverComponent;
