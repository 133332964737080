import React from "react";
import { Modal } from "antd";
import classNames from "classnames";
import "antd/es/modal/style/css";

import "./style.scss";

export default ({ children, className, ...props }) => (
  <Modal {...props} className={classNames(className, "modal-custom-default")}>
    {children}
  </Modal>
);
