export default {
  IS_NOT_LOGIN: "IS_NOT_LOGIN",
  AUTH: {
    STUDENT: "STUDENT",
    TEACHER: "TEACHER",
  },
  HEIGHT_HOUR: 92,
  START_HOUR: "08:00:00",
  LEVEL: [
    { name: "6ème", value: "6" },
    { name: "5ème", value: "5" },
    { name: "4ème", value: "4" },
    { name: "3ème", value: "3" },
    { name: "2nd", value: "2" },
    { name: "1ère", value: "1" },
    { name: "Terminal", value: "TERMINAL" },
  ],
  ROLE: {
    OWNER: "OWNER",
    MANAGER: "MANAGER",
  },
  PAGE_SIZE: 9,
  TYPE_HANDLE_HOLIDAY: {
    CREATE: 1,
    CANCEL: 2,
  },
  STATUS_INTERVIEW: {
    BEFORE_INTERVIEW: "BEFORE_INTERVIEW",
    IN_INTERVIEW: "IN_INTERVIEW",
    ACCEPT: "ACCEPT",
    REJECT: "REJECT",
  },
};
